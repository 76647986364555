import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom"

import FormScreen from './components/FormScreen'
import Step2Screen from './components/Step2Screen'
import ThanksScreen from './components/ThanksScreen'

const App = () => {
  return (
    <Router>
      <Route exact path="/" component={FormScreen} />
      <Route exact path="/contact-us" component={Step2Screen} />
      <Route exact path="/thanks" component={ThanksScreen} />
    </Router>
  );
}

export default App;
