import header from './../img/logo-drepacare.png';
import footerLogo from './../img/logo-drepacare.png';
import imgTopLeft from './../img/img_top-left.png';
import imgBottomRight from './../img/img_bottom-right.png';
import './Form.css';
import React, { useState, useEffect, useRef } from 'react';
import QuestionRadioGroup from './QuestionRadioGroup'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { firebaseApp, firestore } from '../helpers/firebase'
import { withStyles } from '@material-ui/core/styles';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
  } from '@material-ui/pickers';
import moment from 'moment'
import MomentUtils from '@date-io/moment';

import 'moment/locale/fr'  // without this line it didn't work
moment.locale('fr')

const FormScreen = ({history}) => {
    const formRef = useRef(null)

    const emailRef = useRef("")
    const nameRef = useRef("")
    const telephoneRef = useRef("")
    const societeRef = useRef("")

    const [emailValid, setEmailValid] = useState(true)
    const [nameValid, setNameValid] = useState(true)
    const [telephoneValid, setTelephoneValid] = useState(true)

    const [buttonDisabled, setButtonDisabled] = useState(false)

    const [beginDate, setBeginDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());

    const [question1, setQuestion1] = useState(-1)
    const [question2, setQuestion2] = useState(-1)

    useEffect(() => {
        console.log('test1')
        const query = new URLSearchParams(window.location.search);
        if (query.get('email') !== null){
            emailRef.current.value = query.get('email')
        }
    }, [])

    const sendButtonClicked = () => {
        history.push("/contact-us")    
    }

    const CustomButton = withStyles({
        root: {
            textTransform: 'none',
            width: 150,
            height: 45,
            fontSize: 16,
            color: '#0078C1',
            border: '2px solid',
            borderRadius: 5,
            fontFamily: 'Neo Sans Pro Regular',
            backgroundColor: '#FFFFFF',
            borderColor: '#0078C1',
            '&:hover': {
                backgroundColor: '#0078C1',
                borderColor: '#0078C1',
                color: '#FFFFFF',
                boxShadow: 'none',
            },
        },
      })(Button);
    
    return (
        <div className="Container">
            <div className="HeaderClass">
                <img className="HeaderImg" src={header} alt="header" />
                <div className="HeaderClassBloc">
                <CustomButton className="ButtonStyle" disabled={buttonDisabled} color="primary" onClick={sendButtonClicked}>Nous contacter</CustomButton>
                </div>
            </div>
            <div className="Title">Conditions générales d'utilisation</div>
            <div className="Form">
                <div>Date de la publication : 8 juin 2021</div>
                <div>Date de la dernière mise à jour : 10 octobre 2021</div>
                <div style={{height: '30px'}}></div>
                <div>Les présentes conditions d'utilisation (le présent “Contrat”) sont un accord
                    juridique entre vous ( “vous”, “Membre” ou “Utilisateur”) et Drepacare
                    (“Drepacare”, “nous” ou “nos”) concernant l'utilisation de l'application mobile
                    Drepacare, des serveurs utilisés par l'application, des fichiers informatiques
                    stockés sur ces serveurs, de tous les services connexes, ainsi que de toutes les
                    fonctionnalités et de tous les contenus connexes proposés par Drepacare
                    (collectivement dénommés “Application”). Le présent Contrat n'est conclu ni avec
                    Apple, Inc. ou ses filiales, ni avec Google ou ses filiales, ni avec aucune autre
                    entité, le cas échéant.
                </div>
                <div style={{height: '30px'}}></div>
                <div className="TitleSection">1. Acceptation des conditions</div>
                <div style={{height: '30px'}}></div>
                <div>Veuillez lire attentivement le présent Contrat. En créant un compte, en accédant à
                    l'Application ou en l'utilisant, vous reconnaissez que vous acceptez d'être lié par
                    les termes du présent Contrat. SI VOUS N'ACCEPTEZ PAS LES PRÉSENTES
                    CONDITIONS, VOUS NE DEVEZ PAS UTILISER L'APPLICATION OU Y
                    ACCÉDER.</div>
                <div>Les présentes conditions générales entrent en vigueur sans réserve à l’égard des
                    Utilisateurs automatiquement au jour de leur première navigation au sein de
                    l’application Drepacare. Elles demeurent en vigueur tout au long de leur
                    navigation au sein de l’application Drepacare. Les présentes conditions générales
                    entrent en vigueur sans réserve à l’égard des utilisateurs au jour de leur
                    acceptation par chaque utilisateur de l’application Drepacare à la fois par le biais
                    d’une case à cocher formalisant leur consentement au titre de leur acceptation
                    des conditions générales d’utilisations et des mentions légales et par le biais
                    d’une case à cocher distincte formalisant leur consentement au titre du traitement
                    de leurs données de santé à caractère personnel après avoir pris connaissance
                    de la notice d’information et de consentement.
                </div>
                <div style={{height: '30px'}}></div>
                <div>Les présentes conditions générales d'utilisation demeurent en vigueur pour toute
                    la durée du maintien de l’inscription de l’utilisateur aux services fournis par 
                    Drepacare. La cessation de l’inscription prend effet soit au jour de la demande de
                    désinscription de l’utilisateur, soit au jour de la résiliation de son inscription par
                    Drepacare, dans les conditions décrites aux présentes conditions générales.</div>
                <div style={{height: '30px'}}></div>
                <div>Drepacare se réserve le droit d’apporter à tout moment des modifications qu’il
                    jugera nécessaires et utiles aux présentes CGU. Si nous apportons des
                    changements qui modifient de manière substantielle vos droits, nous vous en
                    informerons soit par courrier électronique, soit via l'Application ou en vous
                    présentant une nouvelle version du Contrat soumise à votre acceptation. Si vous
                    continuez à utiliser l'Application après la date d'entrée en vigueur d'une version
                    actualisée du Contrat vous serez réputé avoir accepté le Contrat tel que modifié.</div>
                <div style={{height: '30px'}}></div>
                <div className="TitleSection">2. Clause de non responsabilité des services médicaux</div>
                <div style={{height: '30px'}}></div>
                <div>Drepacare n'est pas un fournisseur de soins médicaux agréé et l'application n'est
pas destinée à remplacer des conseils médicaux donnés par vos professionnels
de santé ou à diagnostiquer, à traiter ou à gérer toute maladie ou problème
médical. Veuillez consulter un médecin agréé ou un autre prestataire de soins de
santé qualifié avant de prendre toute décision ou de mettre en œuvre toute action
pouvant affecter votre santé et votre sécurité ou celle de votre famille. Ne négligez
jamais ou ne retardez jamais le recours à des conseils médicaux au motif que
vous avez lu ou reçu certaines informations sur cette application. Consultez
toujours votre médecin si vous avez des doutes ou des questions sur votre santé
ou votre condition, ou si votre état de santé ou votre condition s'aggrave. Dans le
cas d'une urgence médicale, appelez le 112 (UE) ou rendez-vous immédiatement
au service d'urgence le plus proche.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">3. Inscription et éligibilité</div>
                <div style={{height: '30px'}}></div>
                <div>Pour utiliser l'Application, vous pouvez être amené à créer ou à mettre à jour un
compte (« Compte ») et à fournir certaines informations personnelles, telles que
votre nom, prénom, pays, ville, votre sexe, poids, taille, votre profession, vos
informations de santé sur votre maladie, votre date de naissance et votre adresse
électronique. Ces informations seront conservées et utilisées conformément à
notre Politique de confidentialité, qui peut être consultée sur l’application et
également à l'adresse: www.drepacare.com (« Politique de confidentialité »). 
Vous acceptez de fournir des informations exactes et complètes à la Drepacare et
de mettre à jour ces informations.</div>
<div>L’accès à l’application Drepacare est soumis aux présentes CGU et plus
généralement à la loi française.</div>
<div>L’accès à l’application Drepacare est réservé aux personnes physiques de plus de
18 ans. Pour les mineurs ou les personnes majeures vulnérables, l’accès à
l’application est nécessairement effectué et mis en œuvre par son représentant
légal. L’utilisateur peut ainsi utiliser l’application Drepacare pour son propre
compte, pour le compte d’un patient mineur sur lequel il dispose de l’autorité
parentale ou d’une personne majeure qu’il représente légalement conformément
au droit français. Drepacare se réserve la possibilité de demander tout justificatif
de nature à établir l’autorité parentale ou le pouvoir de représentation légal de
l'utilisateur.</div>
<div>En l’absence de possibilité technique permettant d’authentifier l’âge des
Utilisateurs ou des Membres, il incombe à chaque Utilisateur ou Membre de
mettre en œuvre les dispositifs de sécurité adéquats permettant une protection et
une restriction des accès des mineurs dans l’Application Drepacare.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">4. Les services</div>
                <div style={{height: '30px'}}></div>
                <div style={{marginTop: '10px'}}>L’inscription aux services fournis via l’Application Drepacare est gratuite, les frais
de connexion au réseau internet étant à la charge exclusive des Utilisateurs et
des Membres. Drepacare se réserve le droit d’appliquer des frais d'abonnement
dans le cadre de services supplémentaires optionnels soumis à l’acceptation
préalable des utilisateurs.</div>
<div style={{marginTop: '10px'}}>L’accès et l’utilisation de l’Application Drepacare sont soumis aux présentes
conditions générales et à l’ensemble des dispositions légales et réglementaires en
vigueur. Les services fournis via l’Application Drepacare ne se substituent en
aucun cas à la consultation des professionnels de santé compétents. Les
Utilisateurs ne doivent en aucun cas utiliser les informations diffusées via
l’Application Drepacare pour établir un diagnostic ou déterminer un traitement, et
doivent consulter les professionnels de santé en charge de leur suivi et de leur
prise en charge avant toute décision médicale.</div>

<div style={{marginTop: '10px'}}>Les informations diffusées par Drepacare (qui se distinguent des informations
diffusées par les Membres du Forum Drepacare) sont strictement générales et
indicatives et ne peuvent être parfaitement exemptes d’erreur, Drepacare mettant
tous les moyens en œuvre afin de pouvoir les actualiser régulièrement. Les outils
mis à la disposition des utilisateurs ne constituent en aucun cas un dispositif
médical ni un acte de télémédecine. Drepacare n’est en aucun cas en mesure
d'évaluer l’état de santé d'un membre, y compris à travers les données qu’il a
renseignées via l’application Drepacare et le cas échéant la situation d’urgence
éventuelle dans laquelle se trouve l’utilisateur, qui est seul responsable de toute
décision prise, et de toute action mise en œuvre, à partir des informations saisies
ou lues via l’application Drepacare.</div>

<div style={{marginTop: '10px'}}>En aucun cas, Drepacare n’effectue de vérification ou de contrôle de cohérence
ou de pertinence (notamment au plan scientifique, médical ou sanitaire) des
données saisies par l'utilisateur. Aucun avis médical rendu par un professionnel
ne doit être négligé ou retardé du fait d’une information lue via l’application
Drepacare. Toute situation d’urgence médicale doit conduire l’utilisateur à
contacter immédiatement son médecin traitant ou à composer les numéros
d’urgence tels que le 15. Drepacare n’a en aucun cas sélectionné, validé, choisi
ou ne recommande les professionnels de santé, les produits, avis, opinions ou
d'autres informations qui peuvent être spontanément mentionnés sur le forum
Drepacare par les membres du forum ou au sein de tout espace publicitaire.
Toute utilisation par un utilisateur ou un tiers d’une information quelconque
diffusée via l’application Drepacare par Drepacare ou par les Membres du Forum
Drepacare intervient aux risques et périls de la personne concernée. Les services
fournis par Drepacare dans l’Application Drepacare sont principalement les
suivants :</div>

<div style={{marginTop: '10px', fontWeight: 'bold'}}>Drepacare est dédié aux patients et à leurs proches souhaitant s’informer et
avoir un suivi personnalisé. </div>
<div style={{marginTop: '10px', fontWeight: 'bold'}}>Les professionnels de la santé et les associations peuvent également
s’inscrire afin de s’informer, parcourir l’application, et la proposer à leurs
patients et/ou adhérents. </div>
<div style={{marginTop: '10px', fontWeight: 'bold'}}>Un ensemble de fonctionnalités est proposé aux utilisateurs inscrits sur
l’Application:</div>
<div style={{marginTop: '10px'}}>
    <ul>
        <li>Espace de suivi de l’actualité: articles, témoignages, guides, répertoires, évènements</li>
        <li>Chat téléconseils: échanges sur sa pathologie avec des professionnels de
santé et des professionnels de la drépanocytose;</li>
        <li>Profil avec carte d’urgence digitale : espace des principaux indicateurs de
sa pathologie (exemples : hémoglobine, poids, taille, groupe sanguin, vaccin, type
de drépanocytose, etc …). ;</li>
        <li>Statistiques et graphiques santé : synthèse graphique des éléments
renseignés à imprimer et à partager avec son médecin lors de la consultation
médicale;</li>
        <li>Traitements : saisie de ses traitements médicamenteux en cours avec
        possibilité d’indiquer les effets indésirables éventuels et de donner son avis;</li>
        <li>Rendez-vous médicaux : système automatisé de rappel de ses rendezvous médicaux ;</li>
        <li>Hydratation: enregistrer son objectif hydrique journalier, avoir des rappels</li>
        <li>Douleur: enregistrer ses douleurs, seuil, localisation etc...</li>
        <li>Hospitalisation: enregistrer ses hospitalisations, ses transfusions</li>
        <li>Urgence: envoyer un message géolocalisé à ses proches en cas de crise</li>
        <li>Etudes, recherches, enquêtes : possibilité de répondre à des
        questionnaires proposés par Drepacare pour son compte ou pour le compte de
        ses partenaires contractuels. </li>
        <li>Espace de suivi de l’actualité et de l’activité des membres de sa
        communauté;</li>
        <li>Forums de discussion : partage et échanges sur sa pathologie avec
        l’ensemble des membres de sa communauté dans plusieurs groupes à thèmes.</li>
    </ul>
</div>

<div style={{marginTop: '10px'}}>Les services offerts via l’Application Drepacare sont susceptibles d’évoluer, ce
que l'utilisateur accepte et ce dont il sera informé régulièrement. Compte tenu de
l’évolution et des progrès des connaissances scientifiques et médicales, les
Utilisateurs de l’Application Drepacare acceptent que les informations diffusées
ne soient pas exhaustives et peuvent ne pas être immédiatement à jour.</div>

<div style={{height: '30px'}}></div>
                <div className="TitleSection">5. Votre utilisation de l'Application</div>
                <div style={{height: '30px'}}></div>
                <div style={{marginTop: '10px'}}>Si vous pensez vous trouver en état d'urgence médicale, appelez immédiatement
votre médecin, le 112 (UE) ou rendez-vous immédiatement au service d'urgence
le plus proche.</div>
                <div style={{marginTop: '10px'}}>En tant qu'utilisateur de l'Application, vous acceptez de ne pas l'utiliser à des fins
interdites par le présent Contrat. Vous êtes responsable de toutes vos activités en
relation avec l'Application et vous devez vous conformer à toutes les lois et
réglementations locales, étatiques, nationales et internationales, ainsi qu'à tous
les codes de réglementation applicables. Vous convenez que si vous vous livrez à
l'une des actions suivantes, vous violerez alors le présent Contrat, et vous
acceptez de NE PAS :</div>
                <div style={{marginTop: '10px'}}>а. Revendre, louer, louer à bail, prêter, sous-licencier, distribuer ou autrement
transférer des droits sur l'Application ;</div>

<div style={{marginTop: '10px'}}>b. Modifier, rétro-concevoir, décompiler ou désassembler l'Application ;</div>
<div style={{marginTop: '10px'}}>c. Copier, adapter, altérer, modifier, traduire l'Application ou en créer des œuvres
dérivées sans l'autorisation écrite de Drepacare;</div>
<div style={{marginTop: '10px'}}>d. Permettre à d'autres personnes d'utiliser l'Application via tout moyen, sauf dans
le cas d’un compte avec plusieurs utilisateurs comme permis et prévu par
l’application, limité à 3 utilisateurs par compte dans le contexte familiale (ex: un
compte: une maman avec ses deux enfants, donc trois profils) en vertu des
termes du présent Contrat ; </div>
<div style={{marginTop: '10px'}}>e. Contourner ou désactiver toute fonctionnalité ou moyen technologiques de
l'Application dédiés à la protection des droits de propriété intellectuelle ;</div>
<div style={{marginTop: '10px'}}>f. Utiliser l'Application pour tenter de contourner les moyens technologiques
employés pour contrôler l'accès à un fichier de contenu ou autre œuvre protégés
par les lois sur les droits d'auteur de toute juridiction ainsi que les droits sur ceuxci ou utiliser l'Application en association avec tout appareil, programme ou service
conçu aux fins de ce type de contournement ;</div>
<div style={{marginTop: '10px'}}>g. Utiliser l'Application ou accéder à celle-ci pour compiler des données d'une
manière qui est utilisée ou utilisable par un produit ou service concurrentiel ;</div>
<div style={{marginTop: '10px'}}>h. Utiliser votre Compte pour annoncer, solliciter ou transmettre des publicités
commerciales, y compris des chaînes de lettres, des courriers électroniques
indésirables ou des messages répétitifs à quiconque ;</div>
<div style={{marginTop: '10px'}}>i. Utiliser votre Compte pour adopter une conduite illégale ;</div>
<div style={{marginTop: '10px'}}>j. Téléverser pour transmettre toute communication enfreignant ou violant les
droits d'une partie ;</div>
<div style={{marginTop: '10px'}}>k. Téléverser des supports de toute nature contenant des expressions de haine,
d'abus, des images ou des comportements offensants, de l'obscénité, de la
pornographie, du materiel sexuellement explicite, ou tout matériel pouvant
entraîner une responsabilité civile ou pénale en vertu de la loi ou de la
réglementation en vigueur ou qui pourrait autrement être en conflit avec le présent
Contrat et la Politique de confidentialité de Drepacare ; </div>
<div style={{marginTop: '10px'}}>l. Téléverser tout matériel contenant des virus logiciels ou tout autre code
informatique, fichiers ou programmes conçus pour interrompre, détruire ou limiter
la fonctionnalité de tout logiciel informatique ou de l’application ou de notre site
web.</div>
<div style={{marginTop: '10px'}}>Toute utilisation interdite de tout ce qui est énuméré ci-dessus mettra
immédiatement fin à votre licence d'utilisation de l'Application.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">6. Licence limitée pour l'Application</div>
                <div style={{height: '30px'}}></div>
                <div style={{marginTop: '10px'}}>Nous vous concédons une licence personnelle, mondiale, révocable, non
transférable et non exclusive pour accéder à l'Application et l'utiliser à des fins
personnelles et non commerciales conformément aux termes du présent Contrat.
Tous les droits, titres et intérêts relatifs à l'Application non expressément
concédés dans le présent Contrat sont réservés par Drepacare. Si vous souhaitez
utiliser le logiciel, le titre, le nom commercial, la marque commerciale, la marque
de service, le logo, le nom de domaine de Drepacare et/ou toute autre
identification avec des caractéristiques de marque notables ou tout autre contenu
appartenant à Drepacare, vous devez obtenir l'autorisation écrite de Drepacare.
Les demandes d'autorisation peuvent être envoyées à contact@drepacare.com</div>
                <div style={{marginTop: '10px'}}>Pour lever tout doute, Drepacare détient les droits sur tous les textes, toutes les
images, toutes les photos, tout ce qui est audio ou vidéo, toutes les données de
localisation et toutes autres formes de données ou de communication que
Drepacare crée et met à disposition en relation avec l'application, y compris, sans
toutefois s'y limiter, les interfaces visuelles, les fonctionnalités interactives, les
graphiques, la conception, la compilation du Contenu utilisateur et la compilation
des évaluations agrégées des utilisateurs et tous les autres éléments et
composants de l'application, à l'exclusion du Contenu utilisateur (tel que défini cidessous). Sauf disposition expresse et non ambiguë des présentes, nous ne vous
accordons aucun droit explicite ou implicite, et nous conservons tous les droits
relatifs à l'application et au contenu de Drepacare.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">7. Licence du Contenu utilisateur</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>L'Application vous permet de saisir des notes personnelles, de partager vos
histoires, de publier ou de téléverser du contenu, de soumettre du contenu et
d'enregistrer certaines informations dans l'application (« Contenu utilisateur »).
Vous conservez tous les droits sur le Contenu utilisateur que vous publiez,
partagez ou enregistrez dans l'Application.</div>
<div style={{marginTop: '10px'}}>En fournissant votre Contenu utilisateur à l'Application, vous:</div>
<div style={{marginTop: '10px'}}>(a) concédez à Drepacare une licence non exclusive, transférable, souslicenciable, mondiale, libre de droits pour utiliser, copier, exploiter, modifier,
afficher ou exécuter publiquement, dériver de nouvelles œuvres, intégrer à
d’autres travaux, changer, reformater et distribuer votre Contenu utilisateur dans
le cadre de la fourniture et de l’exploitation de l’Application et des services
connexes et/ou à des fins promotionnelles de Drepacare (par exemple, en
l'affichant sur notre site Web, dans l’Application, sur les réseaux sociaux, sur tout
site Web ou plate-forme sur Internet, selon ce que nous jugerons approprié), sous
réserve de la Politique de confidentialité ;
et (b) vous acceptez d’indemniser Drepacare et ses sociétés affiliées,
administrateurs, dirigeants et employés et de les tenir à couvert de toute
réclamation et de toute dépense, y compris les frais d’avocat, découlant des
supports et/ou de votre non-respect des conditions énoncées dans le présent
Contrat.</div>
<div style={{marginTop: '10px'}}>Drepacare se réserve le droit d'étudier tout le Contenu utilisateur avant de le
soumettre à l'application et de supprimer tout support pour quelque raison que ce
soit, à tout moment, sans préavis, à notre seule discrétion.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">8. Utilisation à vos propres risques</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Notre objectif est d'aider à rendre certaines informations relatives à la santé plus
facilement disponibles et utilisables pour vous. Cependant, concernant la santé,
l'Application ne peut pas et ne garantit pas des améliorations ou des résultats.
L'utilisation que vous faites de l'Application ainsi que toute information, prédiction
ou suggestion fournie dans l'Application sont à vos seuls risques. Nous
n'assumons aucune responsabilité et n'offrons aucune garantie quant à
l'exactitude des données, informations, estimations et prédictions que nous
pourrions vous fournir par le biais de l'Application et vous acceptez et comprenez
que l'Application n'est pas conçue pour correspondre au même objectif ou y
répondre qu'un appareil médical ou scientifique.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">9. Chat Téléconseils</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>L'application mobile propose un abonnement Premium qui vous donne accès au
chat téléconseils. Cet abonnement inclut la 1ère question gratuite, pendant
laquelle vous pouvez découvrir cette fonctionnalité gratuitement. </div>
<div style={{marginTop: '10px'}}>Drepacare chat téléconseils ci-après “Chat Téléconseils” est une fonctionnalité
spécifique de l'application permettant aux utilisateurs de communiquer avec des
professionnels de santé, et professionnels spécialisés en drépanocytose sur des
sujets différents liés à la santé et au bien-être. </div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">9.1 Condition d’une conversation</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Le Chat Téléconseils est une solution de messagerie, de conseils simple et
sécurisée, à destination des drépanocytaires, leurs proches ou tout ceux ayant
besoins de conseils sur le sujet de la drépanocytose délivré par des
professionnels de santé et des spécialistes de la drépanocytose. </div>
<div style={{marginTop: '10px'}}>Chaque utilisateur peut commencer une conversation en posant une question sur
le “Chat Téléconseils” mise à sa disposition sur l’application Drepacare. Il est de 
sa propre responsabilité dans ce cadre d’apporter toutes les informations
nécessaires au professionnel pour formuler un conseil sur la situation exposée.
Les professionnels apportent leur réponse, sur la base de la question posée et
des informations communiquées par l’utilisateur. Une notification est envoyée à
l’utilisateur lorsque le professionnel répond à la question qu’il a posée.</div>
<div style={{marginTop: '10px'}}>En utilisant le “Chat Téléconseils”, les utilisateurs reconnaissent et acceptent
expressément que :</div>
<div style={{marginTop: '10px'}}>
    <ul>
        <li>Drepacare propose des plages horaires pour chaque professionnel. En
dehors de ces plages horaires, Drepacare ne garantit pas de réponses, ni la
disponibilité des professionnels.</li>
        <li>Drepacare propose des plages horaires en se basant sur le fuseau horaire
Parisien. Il ne garantit donc pas une disponibilité en dehors de ce dernier.</li>
        <li>Les professionnels peuvent mettre fin à une conversation à tout moment et
à son libre choix.</li>
        </ul>
        </div>
<div style={{marginTop: '10px'}}>Sous réserve de ces conditions, les conversations peuvent être menées sans
limite d’échanges, ni de temps.</div>
<div style={{marginTop: '10px'}}>Les Utilisateurs ont la possibilité de remonter à Drepacare tous retours qu’ils
estimeront utiles sur la qualité des Services et les conditions dans lesquelles les
Conversations sont lancées et menées.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">9.2 Droit du malade</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Drepacare reconnaît que les messages échangés avec les professionnels sont
couverts par le secret médical. Le Professionnel est garant du respect du secret
médical. Drepacare s’engage à réaliser les prestations décrites dans le Contrat
sans y porter atteinte. Lorsque la réalisation des prestations implique ou est
susceptible d’impliquer l’accès aux messages échangés, Drepacare s’engage à
soumettre tout membre de son personnel ayant directement ou indirectement
accès à ces messages échangés à une obligation de confidentialité et à
superviser tout accès.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">9.3 Prix </div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Le prix du Chat Téléconseils est souscrit sous forme d’abonnement (ci-après : “
l’Abonnement “), dont les tarifs sont indiqués sur l’application et sur notre site
www.drepacare.com. Sauf mention contraire, ces tarifs sont indiqués en Euros et
toutes taxes françaises comprises. Drepacare se réserve le droit, à sa libre
discrétion et selon des modalités dont elle sera seule juge, de proposer des offres
promotionnelles ou réductions de prix.</div>
<div style={{marginTop: '10px'}}>Les prix visés ci-dessus peuvent faire l’objet d’une révision par Drepacare à tout
moment, à sa libre discrétion. Les Utilisateurs seront informés de ces
modifications par Drepacare par tout moyen écrit utile (et notamment par email) 1
(un) mois au moins avant l’entrée en vigueur des nouveaux tarifs.</div>
<div style={{marginTop: '10px'}}>Une fois entrés en vigueur, les nouveaux prix s’appliquent lors du renouvellement
de l’Abonnement. Les Utilisateurs qui n’acceptent pas les nouveaux prix doivent
mettre fin à leur Abonnement, selon les modalités prévues à l’article « Durée des
Services, désinscription ». A défaut, ils seront réputés les avoir acceptés.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">9.4 Abonnements, Durée des Services et désinscription</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>L’Abonnement débute au jour de sa souscription, sous réserve du paiement du
prix conformément à l’article « PRIX», pour la durée souscrite par l’Utilisateur (ciaprès : la « Période Initiale »), de date à date.</div>
<div style={{marginTop: '10px'}}>Il se renouvelle ensuite tacitement, pour des périodes successives de même
durée que la Période Initiale (ci-après désignées, avec la Période Initiale, les «
Périodes »), de date à date, sauf dénonciation effectuée par Drepacare ou par
l’Utilisateur au plus tard 24 (vingt-quatre) heures avant la fin de la Période
d’Abonnement en cours.</div>
<div style={{marginTop: '10px'}}>La dénonciation de l’Abonnement par un Utilisateur s’effectue à travers l’espace
personnel qui lui est réservé sur la boutique en ligne à partir de laquelle il a
téléchargé l’Application.</div>
<div style={{marginTop: '10px'}}>Tout mois entamé est dû dans son intégralité.</div>
<div style={{marginTop: '10px'}}>Il est donné connaissance à l’Utilisateur des dispositions de l’article L215-1 du
Code de la consommation :</div>
<div style={{marginTop: '10px'}}>« Pour les contrats de prestations de services conclus pour une durée déterminée
avec une clause de reconduction tacite, le professionnel prestataire de services
informe le consommateur par écrit, par lettre nominative ou courrier électronique
dédiés, au plus tôt trois mois et au plus tard un mois avant le terme de la période
autorisant le rejet de la reconduction, de la possibilité de ne pas reconduire le
contrat qu'il a conclu avec une clause de reconduction tacite. Cette information,
délivrée dans des termes clairs et compréhensibles, mentionne, dans un encadré
apparent, la date limite de non-reconduction.</div>
<div style={{marginTop: '10px'}}>Lorsque cette information ne lui a pas été adressée conformément aux
dispositions du premier alinéa, le consommateur peut mettre gratuitement un
terme au contrat, à tout moment à compter de la date de reconduction.</div>
<div style={{marginTop: '10px'}}>Les avances effectuées après la dernière date de reconduction ou, s'agissant des
contrats à durée indéterminée, après la date de transformation du contrat initial à
durée déterminée, sont dans ce cas remboursées dans un délai de trente jours à
compter de la date de résiliation, déduction faite des sommes correspondant,
jusqu'à celle-ci, à l'exécution du contrat. Les dispositions du présent article
s'appliquent sans préjudice de celles qui soumettent légalement certains contrats
à des règles particulières en ce qui concerne l'information du consommateur. »</div>
<div style={{marginTop: '10px'}}>Vous ne devez en aucun cas effectuer des paiements avec des cartes de crédit
volées ou des cartes de crédit non autorisées.
Pour obtenir un remboursement ou pour tout autre problème de paiement, veuillez
contacter notre équipe d'assistance contact@drepacare.com</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">9.5 Retard et incident de paiement</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Les Utilisateurs sont informés et acceptent expressément que tout retard de
paiement de tout ou partie d’une somme due à son échéance entraînera
automatiquement la suspension immédiate des Services par la boutique en ligne
auprès de laquelle ils ont souscrit l’Abonnement. </div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">9.6 Droit de rétractation</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Les Utilisateurs sont informés qu’ils bénéficient d’un droit de rétractation, pendant
un délai de 14 (quatorze) jours à compter de leur inscription sur le Chat
Téléconseils. Les conditions et modalités d’exercice de ce droit sont gérés par la
boutique en ligne à partir de laquelle l’Utilisateur a souscrit son abonnement. En
cas de souscription de l’Abonnement sur un terminal iOS d’Apple, ces conditions
et modalités d’exercice du droit de rétractation sont exposées à l’adresse suivante
: https://support.apple.com/fr-fr/HT204084.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">9.7 Obligations des utilisateurs</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>En tant qu'Utilisateurs du Chat Téléconseils, vous vous engagez à respecter les
obligations qui suivent en addition de tout ce, cité précédemment dans l’article 4
du présent Contrat :</div>
<div style={{marginTop: '10px'}}>
    <ul>
        <li>Les Utilisateurs s’engagent dans leurs échanges avec les Professionnels, à
respecter les règles usuelles de politesse et de courtoisie;</li>
        <li>Ne pas Laisser des messages et des déclarations impolis, harcelants, insultants,
provocateurs, discriminants, non tolérants, religieux, racistes, politiques,
homophobes ou offensants ;</li>
        <li>Ne pas publier des images abusives, offensantes, obscènes, pornographiques,
illicites, sexuellement explicites ou tout autre contenu (y compris des liens vers de
tels contenus) interdit par la loi ou la réglementation en vigueur ou pouvant
autrement être en conflit avec le présent Contrat ;</li>
        <li>Ne pas faire de publicité pour n'importe quel produit ou service;</li>
        <li>Ne pas demander leurs informations et coordonnées personnels aux
professionnels</li>
        <li>Ne pas Vous livrer à d'autres actions interdites telles que définies par la loi ou la
réglementation en vigueur en France;</li>
        <li>L’Utilisateur reconnaît avoir pris connaissance sur le Site et/ou dans
l'Application des caractéristiques et contraintes, notamment techniques, de
l'ensemble des Services. Ils sont seuls responsables de leur utilisation des
Services et notamment des relations qu’ils pourront nouer avec les Professionnels
dans le cadre des Conversations. Il leur appartient d’exercer la prudence et le
discernement appropriés dans ces relations et communications;</li>
<li>Les Utilisateurs déclarent expressément être informés et accepter que le
Service Chat Téléconseils n’est pas pris en charge par l’assurance maladie. Il leur 
appartient en conséquence, en cas de problème de santé nécessitant des frais
devant être pris en charge par l’assurance maladie, de prendre rendez-vous
auprès d’un professionnel de santé pour une consultation ou une téléconsultation
remboursable par la sécurité sociale;</li>
<li>Les Utilisateurs sont responsables des informations qu’ils donnent lors d’une
Conversation et des réponses apportées aux demandes de complément
d’informations des Professionnels. Ils déclarent expressément être informés et
accepter que les conseils fournis par les Professionnels dépendent exclusivement
de la pertinence de ces informations et de leurs réponses;</li>
<li>Les Utilisateurs reconnaissent et acceptent expressément que chaque
Conversation ne doit avoir pour seul objet que la question qu’ils ont posée pour
lancer ladite Conversation. En conséquence, ils s’interdisent expressément, lors
de toute Conversation, de poser une question sans rapport avec la question
initiale et/ou de demander au Professionnel avec lequel il échange toute
évaluation globale de leur état de santé. Le Professionnel peut refuser de
répondre;</li>
<li>Les Utilisateurs s’engagent à faire un usage dans un cadre familial des
Services. Ils s’interdisent en conséquence de céder, concéder ou transférer tout
ou partie de leurs droits ou obligations au titre des présentes à un tiers, de
quelque manière que ce soit;</li>
<li>Les Utilisateurs sont informés et acceptent que la mise en œuvre des Services
nécessite qu'ils disposent d’une connexion à internet (frais à leurs charges) et
d’un smartphone (non fourni) et que la qualité des Services dépend directement
de cette connexion, dont il est seul responsable;</li>
        </ul>
        </div>
<div style={{marginTop: '10px'}}>L’Utilisateur reconnaît et accepte que, préalablement à la conclusion du contrat,
Drepacare a mis à sa disposition les conditions générales et lui a délivré, d'une
manière claire et compréhensible, l’ensemble des informations visées à l’article à
l'article L111-1 du Code de la Consommation et notamment les informations
relatives :</div>
<div style={{marginTop: '10px'}}>
    <ul>
        <li>Aux caractéristiques essentielles de l’Application et du Chat Téléconseils ;</li>
        <li>Aux tarifs applicables ;</li>
        <li>A l’identification et aux moyens de contact de Drepacare par e-mail ;</li>
        <li>Aux garanties légales et contractuelles applicables et à leurs modalités de
mise en œuvre ;</li>
        <li>Aux fonctionnalités de l’Application et du Service et, le cas échéant, à leur
interopérabilité.</li>
    </ul>
</div>
<div style={{marginTop: '10px'}}>À notre seule discrétion, nous nous réservons le droit de :</div>
<div style={{marginTop: '10px'}}><ul>
        <li>Supprimer tout message ou matériel inapproprié ou non pertinent ;</li>
        <li>Supprimer ou modifier des messages contenant des données personnelles,
telles que le nom, l'adresse ou l'adresse électronique ;</li>
        <li>Restreindre ou interdire votre accès à Chat Téléconseils à tout moment et sans
préavis si nous déterminons que votre contenu ou votre utilisation du Chat
Téléconseils enfreint le présent Contrat ;</li>
        <li>Utiliser, copier, modifier, remanier, déplacer, changer, afficher publiquement,
organiser et diffuser publiquement des discussions, des messages et des
documents ;</li>
        <li>Bloquer vos messages pour quelque motif que ce soit ou les modérer lorsque
nous le jugerons opportun ;</li>
<li>Désactiver le Chat Téléconseils à tout moment sans notification préalable.</li>
    </ul></div>
    <div style={{height: '30px'}}></div>
                <div className="TitleSection">9.8 Les Services des Professionnels de santé</div>
                <div style={{height: '30px'}}></div>

<div style={{marginTop: '10px', fontWeight: 'bold'}}>Mise en garde :</div>
<div style={{marginTop: '10px'}}>Les services des professionnels de santé sont fournis par les professionnels de
santé aux patients dans le respect de leurs compétences, en exécution de leurs
obligations légales, réglementaires, déontologiques et conventionnelles et sous
leur responsabilité exclusive ; Drepacare n’endosse aucune responsabilité à ce
titre. Les professionnels de santé sont soumis au secret sur les informations de
santé et relatives à la vie privée des patients parvenues à leur connaissance par
quelque moyen que ce soit et ce conformément à leurs obligations légales,
réglementaires et déontologiques telles qu’elles résultent en particulier des
articles l. 1110-4, l. 1110-12, r. 1110-1 et suivants du code de la santé publique,
de l’article 226-13 du code pénal et du code de déontologie régissant l’exercice de
leur profession, le cas échéant.</div>
<div style={{marginTop: '10px'}}>En conséquence, les professionnels de santé ne communiquent en aucun cas,
directement ou indirectement, hors de l'équipe de soins, l’une quelconque des
informations concernant le patient et soumise au secret médical, à tout tiers non
autorisé.</div>
<div style={{marginTop: '10px'}}>En aucun cas, un Professionnel ne peut être soumis à des instructions de
Drepacare dans le domaine médical.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">9.9 Envoie de Documents</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Le Chat téléconseils permet également à l’Utilisateur d’envoyer des Documents
en rapport avec sa question et qui pourront aider le professionnel à y répondre.
Ces envoies s’inscrivent dans le cadre des conseils qui pourront être prodigués
par les professionnels. Ce Service assure un envoie sécurisé des Documents. </div>
<div style={{marginTop: '10px'}}>Aucune information publiée dans le Chat Téléconseils ne doit être considérée
comme une prescription de médicament, une suggestion de traitement, un
diagnostic médical ou une prescription médicale. En cas de doute sur votre santé,
consultez un professionnel de la santé autorisé. N’oubliez pas que si vous vous
trouvez dans une situation d’urgence vous devez consulter de suite votre médecin
ou appeler les numéros d’urgences de votre pays ou vous y rendre dès que
possible.</div>
<div style={{marginTop: '10px'}}>Les Règles de Chat Téléconseils font partie intégrante du présent Contrat. En
acceptant le présent Contrat, vous acceptez également les Règles de Chat
Téléconseils.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">10. Forum Drepacare</div>
                <div className="TitleSection">10.1 LES SERVICES du Forum Drepacare</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>L’accès et l’utilisation du Forum Drepacare sont soumis aux présentes conditions
générales et à l’ensemble des dispositions légales et réglementaires en vigueur.
Les services fournis via Drepacare ne se substituent en aucun cas à la
consultation des professionnels de santé compétents. Les Membres du Forum ne
doivent en aucun cas utiliser les informations diffusées via le Forum Drepacare
pour établir un diagnostic ou déterminer un traitement, et doivent consulter les 
professionnels de santé en charge de leur suivi et de leur prise en charge avant
toute décision médicale.</div>
<div style={{marginTop: '10px'}}>Les informations diffusées par Drepacare (qui se distinguent des informations
diffusées par les Membres du Forum Drepacare) sont strictement générales et
indicatives et ne peuvent être parfaitement exemptes d’erreur, Drepacare mettant
tous les moyens en œuvre afin de pouvoir les actualiser régulièrement. Les outils
mis à la disposition des membres ne constituent en aucun cas un dispositif
médical ni un acte de télémédecine. Drepacare n’est en aucun cas en mesure
d'évaluer l’état de santé d'un membre, y compris à travers les données qu’il a
renseignées via l’application Drepacare et le cas échéant la situation d’urgence
éventuelle dans laquelle se trouve le Membre, qui est seul responsable de toute
décision prise, et de toute action mise en œuvre, à partir des informations saisies
ou lues via l’application Drepacare.</div>
<div style={{marginTop: '10px'}}>En aucun cas, Drepacare n’effectue de vérification ou de contrôle de cohérence
ou de pertinence (notamment au plan scientifique, médical ou sanitaire) des
données saisies par le Membre. Aucun avis médical rendu par un professionnel
ne doit être négligé ou retardé du fait d’une information lue via l’application
Drepacare. Toute situation d’urgence médicale doit conduire le Membre à
contacter immédiatement son médecin traitant ou à composer les numéros
d’urgence tels que le 15. Drepacare n’a en aucun cas sélectionné, validé, choisi
ou ne recommande les professionnels de santé, les produits, avis, opinions ou
d'autres informations qui peuvent être spontanément mentionnés sur le forum
Drepacare par les membres ou au sein de tout espace publicitaire. Toute
utilisation par un Membre ou un tiers d’une information quelconque diffusée via
l’application Drepacare par Drepacare ou par les Membres intervient aux risques
et périls de la personne concernée. Les services fournis par Drepacare dans le
forum Drepacare sont principalement les suivants :</div>
<div style={{marginTop: '10px'}}>Pour les Membres :</div>
<div>
    <ul>
        <li>Espace de suivi de l’actualité et de l’activité des membres de sa communauté ;</li>
        <li>Forums de discussion : partage et échanges sur sa pathologie avec l’ensemble
des membres de sa communauté dans plusieurs groupes à thèmes.</li>
    </ul>
</div>
<div style={{marginTop: '10px'}}>-Les services offerts via le Forum Drepacare sont susceptibles d’évoluer, ce que
le Membre accepte et ce dont il sera informé régulièrement. Compte tenu de 
l’évolution et des progrès des connaissances scientifiques et médicales, les
Utilisateurs et les Membres du Forum Drepacare acceptent que les informations
diffusées ne soient pas exhaustives et peuvent ne pas être immédiatement à jour.</div>
<div style={{marginTop: '10px'}}>Les Membres ne peuvent utiliser les services fournis par Drepacare, les services
et les contenus du Membre, à d’autres fins que pour leur destination définie aux
présentes conditions générales, et notamment s’interdisent d’utiliser Drepacare, y
compris les services et contenus des Membres pour tenter de capter des clients,
pour assurer la promotion d’un produit, d’un service, d’un établissement de santé
ou de manière générale à toute fin publicitaire ou promotionnelle.</div>
<div style={{marginTop: '10px'}}>Les services et les contenus du Forum Drepacare, y compris les services et les
contenus du Membre sont à l'usage personnel des Membres.</div>
<div style={{marginTop: '10px'}}>Le Forum Drepacare est susceptible de contenir des espaces et des contenus
publicitaires qui seront identifiés clairement comme tels.
Nous validons systématiquement chaque nouvel annonceur et nous réservons la
possibilité de refuser une publicité si son contenu pouvait être sujet à controverse
ou s'il n'était pas jugé par notre équipe comme favorisant la santé de manière
générale. Notre ligne éditoriale reste libre et indépendante de toute influence
commerciale ou publicitaire.</div>
<div style={{marginTop: '10px'}}>Notre Forum accueille de la publicité sous forme de bannière. Toutes les
bannières publicitaires sont identifiées par les mots "Annonce sponsorisée".
Ponctuellement, l’application diffuse des publi rédactionnels. Le contenu de
l'article publi rédactionnel est validé par les rédacteurs de l’application. Dans ce
cas de figure, la mention « Annonce sponsorisée » est précisée afin d’éviter toute
ambigüité entre contenu éditorial et apport publicitaire.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">10.2 SERVICES INTERACTIFS ET ESPACES COLLABORATIFS SUR le Forum
Drepacare</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Les Membres peuvent utiliser des services interactifs et des espaces collaboratifs
au sein desquels ils peuvent contribuer librement sans contrôle quelconque
préalable ni fixation préalable sur support matériel de la part de Drepacare. 
Chaque Membre reconnaît que la responsabilité de Drepacare au titre de la mise
à disposition de services interactifs et d’espaces collaboratifs est la responsabilité
en qualité d’intermédiaire technique définie par l’article 6 I 2 de la Loi pour la
Confiance dans l'Économie numérique.</div>
<div style={{marginTop: '10px'}}>Chaque Membre s’engage à respecter inconditionnellement l’ensemble des
dispositions légales en vigueur et en particulier s’engage à l’occasion de son
utilisation des espaces interactifs et des espaces collaboratifs ou des messages
privés à respecter les dispositions suivantes :</div>
<div style={{marginTop: '10px'}}>La protection de la vie privée, des droits de la personnalité et du droit à l’image de
toute personne,
La protection des mineurs,
La protection du secret médical,
La prohibition des propos diffamatoires, injurieux, insultants, dénigrants,
menaçants, abusifs, racistes, xénophobes, violents, incitant à la haine raciale
et/ou à la violence et/ou à la pédophilie et/ou à la pornographie infantile, faisant
l’apologie des crimes contre l’humanité, caractérisant un harcèlement, ou
susceptibles de porter atteinte à la dignité et/ou à la sensibilité des autres
membres,
La prohibition de toute diffusion de contenus pornographiques,
La protection des droits d’auteurs et des droits de la propriété.
Les administrateurs se réservent toutefois le droit de supprimer tout contenu
(propos, photos, adresses...) inapproprié, offensant ou diffamatoire, pour
préserver la qualité des échanges sur le site.</div>
<div style={{marginTop: '10px'}}>Toute contribution directe par les membres au sein des espaces collaboratifs et
interactifs du Forum Drepacare et tout message privé sont rédigés, émis,
échangés et diffusés sous la responsabilité exclusive de leurs auteurs Membres
et à leurs risques et périls. Drepacare se conforme strictement aux dispositions de
la Loi pour la Confiance dans l’économie numérique et à ce titre :</div>
<div style={{marginTop: '10px'}}>Drepacare conserve les données permettant l’identification des Membres
contributeurs pendant toute la durée légale, et pourra les communiquer sur
réquisitions judiciaires,</div>
<div>Drepacare met en place une modération a posteriori des contributions librement
et directement diffusées par les Membres sans contrôle éditorial ni fixation sur
support matériel préalable,</div>
<div>Drepacare n’est pas tenue d’une obligation générale de surveillance des
contributions sur les espaces collaboratifs et interactifs,</div>
<div>Sur demande de tout Membre notifiée dans les formes légales en vigueur,
Drepacare supprime promptement et sans préavis tout message illicite et en
particulier non conforme aux dispositions listées ci-dessus ou dont le contenu
porte atteinte aux droits de tiers.</div>
<div>Les messages privés reçus et envoyés à partir de Drepacare constituent des
correspondances privées au sens de la législation en vigueur ; à ce titre, il est
interdit, sous peine de poursuites pénales, de les diffuser au sein des espaces
collaboratifs et interactifs, ainsi qu’à tous tiers non autorisés, et de manière
générale à destination de tout public sans l’autorisation expresse et préalable de
leurs auteurs. Les Membres accordent à Drepacare le droit d'utiliser, copier,
afficher, exécuter, distribuer, traduire, éditer et créer des œuvres dérivées à partir
de leurs Contenus, sous réserve des termes de la Charte de bonne conduite et de
la politique de confidentialité.Les « Contenus » concernent l’utilisation des
messages du forum et les commentaires. Les Contenus ne concernent pas les
informations échangées dans le cadre des messages privés via Drepacare.</div>
<div style={{marginTop: '10px'}}>Chaque Membre s’engage à ne pas divulguer toute donnée et/ou information se
rapportant à un autre Membre ou à un tiers sans le consentement exprès du
Membre concerné.</div>
<div style={{marginTop: '10px'}}>Les membres sont informés et acceptent que le contenu des contributions
produites au sein des services interactifs et des espaces collaboratifs soient
référencés sur internet, et ce sans les identifier d'une manière quelconque
notamment par leur pseudonyme ou leur prénom de façon à respecter leur vie
privée. La qualité de "patient" ou de "proche" peut être référencée.</div>
<div style={{marginTop: '10px'}}>Drepacare recommande à ses utilisateurs d'utiliser un pseudo qui ne comporte
pas de données directement identifiantes (nom et prénom). Par ailleurs,
Drepacare rappelle à ses utilisateurs que le pseudo est visible sur les pages
publiques du forum.</div>
<div style={{marginTop: '10px'}}>Chaque Membre inscrit sur le Forum Drepacare est susceptible d’être contacté
par Drepacare au regard des informations qu’il/elle a communiquées sur
l’application. (Voir article Données à caractère personnel)</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">10.3 OBLIGATIONS DES MEMBRES Du Forum Drepacare</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Chaque Membre déclare et garantit que toutes les informations et les
renseignements fournis lors de son inscription, et toutes les informations qu'il a
fournies et fournira, sont véridiques, exactes, loyales et non trompeuses. Les
Membres s’engagent à ne pas gêner ou entraver l’utilisation par les autres
Membres du Forum Drepacare.</div>
<div style={{marginTop: '10px'}}>Les Membres ne peuvent pas utiliser le Forum Drepacare afin de mener toute
activité illégale ou qui viole les droits des tiers, fournir des instructions,
informations ou orientations sur des activités illégales.</div>
<div style={{marginTop: '10px'}}>Les Membres s’engagent à ne pas utiliser ni mettre en œuvre de robot ou tout
autre moyen automatisé pour accéder au Forum Drepacare. Chaque Membre
s’engage à diffuser des contenus et informations exemptes de virus, chevaux de
Troie, vers, bombes, ou tout autre outil/moyen logique destinés à endommager,
nuire, intercepter ou entraver tout système de Drepacare, et /ou des données ou
informations diffusées.</div>
<div style={{marginTop: '10px'}}>Les Membres ne peuvent pas utiliser les coordonnées visibles des autres
Membres, ou collecter des informations sur les Membres, pour faciliter l'envoi de
communications de masse non sollicitées tels que spam ou permettre à des tiers
l'utilisation desdites informations sur les Membres.</div>
<div style={{marginTop: '10px'}}>Chaque Membre s’engage à protéger et garantir le maintien de la confidentialité
de son mot de passe et de son login, et est et demeure pleinement responsable
de toutes les activités qui se produisent sous son mot de passe ou compte, qu’il
en ait ou pas connaissance. Le Membre est informé et accepte que Drepacare se
réserve la faculté de prononcer unilatéralement la résiliation des présentes
conditions générales à son égard si le Membre a fourni sciemment son login et
son mot de passe à un tiers.</div>
<div style={{marginTop: '10px'}}>Chaque Membre s’engage à avertir immédiatement Drepacare de toute utilisation
non autorisée de son mot de passe et de son login de Membre ou de toute
connexion en violation de la sécurité de l’accès à son compte.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">10.4 RESPONSABILITE</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>En aucun cas, Drepacare n’est responsable de toute utilisation du Forum
Drepacare , des services qui y sont proposés et des données qui y sont diffusées,
à quelque titre que ce soit, pour quelque décision que ce soit et notamment pour
toute décision médicale qui relève de la seule responsabilité du Membre.
Drepacare ne garantit en aucun cas la conformité et l’adéquation du Forum
Drepacare et des services et informations qui y sont diffusées aux besoins de
Membres et/ou des Utilisateurs. Drepacare ne garantit pas l'accès permanent au
Forum ou un accès sans erreur ou une correction immédiate des erreurs et/ou
dysfonctionnements. Les avis, recommandations, informations et données
diffusées par Drepacare ou par d'autres Membres du Forum Drepacare ne sont
en aucune façon contrôlés, approuvés, validés ou homologués par Drepacare.
Toute utilisation du Forum Drepacare, des services qui y sont proposés et des
données qui y sont diffusées s’effectue aux risques et périls des Membres et des
Utilisateurs. En aucun cas, Drepacare, ses partenaires, collaborateurs, employés,
ou administrateurs ne seront responsables des dommages directs, indirects,
accessoires, particuliers ou consécutifs (même si Drepacare a été avisée de la
possibilité de tels dommages) en raison de l’utilisation du Forum Drepacare ou en
raison de la dépendance d’un Membre à l'égard des contenus ou des services
fournis sur le forum Drepacare.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">10.5 DONNEES A CARACTERE PERSONNEL</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Drepacare est le responsable de traitement, au sens de la Loi 78-17 du 6 janvier
1978 modifiée relative à l’Informatique, aux Fichiers et aux Libertés et du
Règlement UE 2016/679 relatif à la protection des personnes physiques à l’égard
du traitement des données à caractère personnel et à la libre circulation de ces
données du 27 avril 2016, mis en œuvre à partir des données à caractère
personnel des Membres et des Utilisateurs.</div>
<div style={{marginTop: '10px'}}>Les données des Membres peuvent, sous réserve de leur consentement explicite
à ce titre et en exécution des Conditions générales d’utilisation, faire l’objet des
usages ci-après :</div>
<div style={{marginTop: '10px'}}>- bénéficier des services qui les intéressent et auxquels ils souhaitent s’inscrire à
savoir :</div>
<div style={{marginTop: '10px'}}>La diffusion et le partage d’informations et de contenus divers relatifs à la santé,
à la forme et aux affections et pathologies humaines, et à tous produits et/ou
prestations s’y rapportant les intéressant,
Sa mise en relation avec les autres membres du forum Drepacare
L’échange d’informations et de contenus avec les autres Membres du Forum
Drepacare via des messages électroniques privés et/ou via des espaces de
publication communautaire et de discussions interactifs (par exemple fils
d’actualité, forums de discussions, messagerie privée),
Le Membre est informé que dans ce cadre, des données à caractère personnel –
en particulier des données d’identification et des données de santé – le
concernant sont collectées et traitées en exécution des présentes conditions
générales d’utilisation et sur la base de son consentement explicite après avoir
pris connaissance de la Mentions légales et Politique de confidentialité lors de son
inscription.</div>
<div style={{marginTop: '10px'}}>- Les données du Membre pourront également être traitées pour lui permettre de
recevoir de l’information institutionnelle ou promotionnelle de Drepacare ou de ses
partenaires sur les sujets qui l’intéressent sur la base d’un consentement
volontaire, actif et spécifique de sa part. Il pourra par exemple recevoir de
l’information sur des études et enquêtes afin de lui permettre d’y participer s’il le
souhaite et /ou d’être mis en relation vers les organismes chargés de la réalisation
de telles études et/ou enquêtes.</div>
<div style={{marginTop: '10px'}}>- Le Membre peut retirer ses consentements à tout moment et sans justification.
Dans ce cas, il reconnaît qu’il ne pourra plus bénéficier des services accessibles
dans l’application Drepacare et/ou recevoir des informations institutionnelles et
promotionnelles.</div>
<div style={{marginTop: '10px'}}>Le retrait du consentement du Membre ne portera pas atteinte à la licéité du
traitement effectué avant le retrait de son consentement.</div>
<div style={{marginTop: '10px'}}>Les données du Membre pourront également être exploitées dans le cadre
d’études ou d’évaluation dans le domaine de la santé notamment sémantique,
scientifique, médicale, médico-économique, ou de qualité de vie mises en œuvre
par Drepacare pour son compte ou pour le compte de ses partenaires
contractuels, sauf opposition spécifique de la part du Membre pour chaque étude
portée à sa connaissance au préalable, et après mise en œuvre de l’ensemble
des formalités requises auprès de la CNIL lorsqu’elles sont applicables. Les
études sont conduites après l’application de mesures de sécurité renforcées afin
de garantir le respect de sa vie privée.</div>
<div style={{marginTop: '10px'}}>Enfin, Drepacare peut également produire des statistiques dans le cadre de ses
activités et des services accessibles à partir du Forum Drepacare qu’elle met en
œuvre, après l’agrégation des données des Membres et des Utilisateurs ne
permettant plus de les identifier et afin garantir leur anonymat.</div>
<div style={{marginTop: '10px'}}>Les données à caractère personnel du Membre et de l’Utilisateur sont strictement
destinées à Drepacare ainsi qu’aux seuls Membres autorisés spécifiquement à
cet effet, au fur et à mesure de l’utilisation des services du Forum Drepacare par
le Membre.</div>
<div style={{marginTop: '10px'}}>A ce titre, le Membre est expressément informé qu’il est recommandé de choisir
un nom d’usage/pseudonyme associé à une photo non identifiante comme avatar,
plutôt que son identité réelle, afin de protéger sa vie privée au sein de la
communauté.</div>
<div style={{marginTop: '10px'}}>Le Membre apprécie ainsi sous sa propre responsabilité l’opportunité d’apparaître
sur le Forum Drepacare et de participer aux différents services proposés de façon
identifiante ou au contraire de choisir des informations lui permettant de préserver
son anonymat.</div>
<div style={{marginTop: '10px'}}>Le Membre ne souhaitant pas être identifié sur le Forum Drepacare est informé et
accepte que des risques d’identification par d’autres Membres inscrits dans la
même communauté que lui, existent en fonction de la nature et de la précision
des informations qu’il consent à partager au sein de sa communauté.</div>
<div style={{marginTop: '10px'}}>Drepacare recourt à des sous-traitants appliquant des garanties suffisantes au
titre de la mise en œuvre de mesures techniques et organisationnelles
appropriées, afin d’assurer le respect des exigences de confidentialité, de sécurité
et de pérennité de ces données.</div>
<div style={{marginTop: '10px'}}>Le cas échéant, les données du Membre peuvent être destinées aux promoteurs
des études et évaluation en santé, sous réserve de son opposition spécifique à
chaque étude portée au préalable à sa connaissance.</div>
<div style={{marginTop: '10px'}}>Drepacare garantit que les données à caractère personnel des Membres et des
Utilisateurs ne seront en aucun cas transmises à aucun tiers non autorisé.</div>
<div style={{marginTop: '10px'}}>Les données des Membres au titre de l’utilisation des services proposés via le
Forum Drepacare sont conservées pendant toute la durée nécessaire à
l’utilisation desdits services. Si Drepacare constate l’inactivité du compte
personnel d’un Membre pendant 5 ans, Drepacare adressera au Membre un
message l’informant de la clôture de son compte et de la suppression de ses
données à caractère personnel.</div>
<div style={{marginTop: '10px'}}>Conformément à la Règlementation précitée le Membre et l’Utilisateur disposent
chacun :</div>
<div style={{marginTop: '10px'}}>d’un droit d'accès et de rectification des données à caractère personnel les
concernant ;
d’un droit d’opposition et de suppression de leurs données à caractère personnel ;
d’un droit de communiquer leurs directives concernant le sort de leurs données à
caractère personnel après leur mort ;
Le Membre et l’Utilisateur disposent également :</div>
<div style={{marginTop: '10px'}}>du droit de retirer leur consentement à tout moment ;
du droit de solliciter une limitation du traitement ;
d’un droit à l’oubli et à l’effacement numérique ;
d’un droit à la portabilité de leurs données, lorsque ce droit s’applique ;
du droit d’introduire une réclamation auprès de la CNIL.
Pour exercer ses droits, le Membre peut s’adresser à Drepacare directement en
se connectant à son compte personnel et en lui adressant un message privé via
www.drepacare.com</div>
<div style={{marginTop: '10px'}}>Le Membre et l’Utilisateur peuvent également s’adresser au délégué à la
protection des données de Drepacare pour toute question concernant leurs
données à caractère personnel, aux coordonnées suivantes :
dpo@drepacare.com.</div>
<div style={{marginTop: '10px'}}>L’application Drepacare fait usage de cookies. Ceux-ci ne comportent pas de
données nominatives ou d'identification.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">10.6 CONVENTION DE PREUVE</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Les registres informatisés conservés dans les systèmes informatiques de
Drepacare dans des conditions raisonnables de sécurité, seront considérés
comme les preuves des communications et des différentes transmissions des
informations entre l’Utilisateur et/ou le Membre et du Forum Drepacare et
Drepacare. La conservation et l’archivage des informations et des données sont
effectués sur un support fiable et durable conformément à l’article 1366 et
suivants du Code Civil.</div>
<div style={{marginTop: '10px'}}>L’Utilisateur et le Membre reconnaissent et acceptent, qu’après leur
authentification, toute manifestation de leur volonté par le biais de l’utilisation des
fonctionnalités proposées au sein du Forum Drepacare, et en particulier
l’acceptation des CGU et le consentement à la collecte et au traitement des
données de santé des Membres, constitue une signature électronique au sens
des dispositions des articles 1366 et suivants du Code civil, et manifeste leur
consentement en caractérisant sa preuve.</div>
<div style={{marginTop: '10px'}}>Conformément aux dispositions des articles 1366 et suivants du Code Civil, la
mise en œuvre d’une signature électronique, sur la base d’un procédé fiable
d'identification garantissant son lien avec l'acte auquel elle s'attache, est
considérée comme une signature valable et comme une preuve au sens des
dispositions précitées.</div>
<div style={{marginTop: '10px'}}>L’Utilisateur et le Membre ne pourront pas contester la recevabilité, la validité ou
la force probante des éléments sous format ou support électronique précités, sur 
le fondement de quelque disposition légale que ce soit et qui spécifierait que
certains documents doivent être écrits ou signés pour constituer une preuve.
Ainsi, les éléments considérés constituent des preuves et, s’ils sont produits
comme moyens de preuve par Drepacare dans toute procédure contentieuse ou
autre, seront recevables, valables et opposables de la même manière, dans les
mêmes conditions et avec la même force probante que tout document qui serait
établi, reçu ou conservé par écrit.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">10.7 LOI APPLICABLE</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Les présentes conditions générales sont régies par la loi française. Les
utilisateurs étrangers acceptent expressément l’application de la loi française en
utilisant le Forum Drepacare. En conséquence, les Utilisateurs reconnaissent que
:</div>
<div style={{marginTop: '10px'}}>les pratiques médicales évoquées au sein du Forum Drepacare,
les références citées au sein du Forum Drepacare,
et de manière générale, toute information qui y est diffusée et/ou échangée, sont
susceptibles de ne pas être cohérentes ou appropriées en dehors de la France.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">10.8. DISPOSITIONS GENERALES</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Drepacare peut céder les présentes conditions générales à tout moment à une
filiale ou à un successeur, quelle que soit l’opération. Le Membre et/ou l’Utilisateur
ne sont pas autorisés à céder leur accord avec Drepacare. Drepacare se réserve
le droit de faire évoluer le Forum Drepacare à tout moment sans préavis ni
information préalable des Membres et/ou Utilisateurs. Si une ou plusieurs
stipulations des présentes conditions générales sont tenues pour non valides ou
déclarées telle en application d'une loi, d'un règlement ou à la suite d'une décision
définitive d'une juridiction compétente, les autres stipulations garderont toute leur
force et leur portée.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">11. Mots de passe</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>La responsabilité de prendre toutes les mesures raisonnables pour s'assurer
qu'aucune personne non autorisée n'aura accès à vos mots de passe de
l'Application ou à votre compte vous incombe.
Il est de votre seule responsabilité </div>
<div>(1) de contrôler la diffusion et l'utilisation du nom de connexion, du nom, prénom
et des mots de passe ; </div>
<div>(2) d'autoriser, de surveiller et de contrôler l'accès et l'utilisation de votre compte
et mot de passe dans l'Application ; </div>
<div>(3) d'informer rapidement Drepacare si vous pensez que votre compte ou votre
mot de passe a été compromis ou s'il y a une autre raison pour laquelle vous
devez désactiver un mot de passe. Envoyez-nous un courrier électronique à
contact@drepacare.com Vous concédez à Drepacare et à toutes les autres
personnes ou entités impliquées dans le fonctionnement de l'Application le droit
de transmettre, surveiller, récupérer, stocker et utiliser vos informations en rapport
avec le fonctionnement de l'Application. Drepacare ne peut assumer et n'assume
aucune responsabilité pour les informations que vous soumettez, ni pour votre
utilisation ou celle de tiers, ou pour une mauvaise utilisation des informations
transmises ou reçues à l'aide de l'Application.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">12. Exclusion de garantie</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Drepacare contrôle et exploite l'Application à partir de divers emplacements et ne
déclare aucunement que l'Application est appropriée ou disponible pour une
utilisation sur tout site. L'Application ou certaines de ses fonctionnalités peuvent
ne pas être disponibles dans votre pays ou peuvent varier d'un pays à un autre.</div>
<div style={{marginTop: '10px'}}>L'application est fournie « telle quelle », « telle que proposée » et sans aucune
représentation ou garantie d'aucune sorte, expresse ou implicite, y compris, mais
sans s'y limiter, les garanties implicites de titre, de non-contrefaçon, de qualité
marchande et d'adéquation pour un usage particulier et toute garantie impliquée
par toute exécution ou utilisation commerciale, toutes étant expressément
exclues, sauf dans la mesure requise par la loi. </div>
<div style={{marginTop: '10px'}}>La société et ses administrateurs, employés, agents, représentants, fournisseurs,
partenaires et fournisseurs de contenu ne garantissent pas que : </div>
<div>(a) l'application sera disponible à tout moment ou à tout endroit particulier ; </div>
<div>(b) tout défaut ou toute erreur sera corrigé ; </div>
<div>(c) tout contenu ou logiciel disponible sur ou par l'application est exempt de virus
ou d'autres composants nocifs ; </div>
<div>(d) les résultats de l'utilisation de l'application répondront à vos besoins. votre
utilisation de l'application se fait uniquement à vos propres risques ; ou</div>
<div>(e) l'exactitude, la fiabilité ou l'exhaustivité du contenu, du texte, des images, du
logiciel, des graphiques ou des communications fournies par des tiers sur ou par
l'application. </div>
<div>Certains états/pays ne permettent pas de limiter les garanties implicites, donc, les
limitations ci-dessus peuvent ne pas s'appliquer à vous.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">13. Limite de responsabilité</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>En aucun cas, Drepacare, ses dirigeants, administrateurs, agents, sociétés
affiliées, employés, annonceurs ou fournisseurs de données ne peuvent être
tenus responsables de tout dommage indirect, spécial, accessoire ou punitif (y
compris, mais sans s'y limiter, la perte d'utilisation, la perte de profits ou la perte
de données) que ce soit une action contractuelle, un délit (y compris, mais sans
s'y limiter, la négligence), l'équité ou autrement, découlant de toute manière liée à
l'utilisation de cette application. </div>
<div>En aucun cas, la responsabilité totale de Drepacare découlant ou en relation avec
ces conditions ou avec l'utilisation ou l'incapacité d'utiliser l'application, ne
dépassera les montants que vous avez versés à Drepacare pour l'utilisation de
l'application ou cent euros (100€) si vous n'aviez aucune obligation de paiement
envers la société, le cas échéant. Certaines juridictions n'autorisant pas
l'exclusion ou la limitation de responsabilité, il est possible que les limitations
susmentionnées ne s'appliquent pas à vous. </div>
<div>Drepacare ou tout tiers mentionné sur l'application ne peuvent être tenus
responsables pour aucune blessure personnelle, y compris la mort, causée par
votre utilisation ou mauvaise utilisation de l'application.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">14. Utilisation d'appareils mobiles</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Veuillez noter que les tarifs et frais normaux de votre opérateur, tels que les frais
de messagerie textuelle et de données, continueront de s'appliquer si vous utilisez
l'application sur un appareil mobile.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">15. Services tiers</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>L'Application peut vous donner accès à des liens vers des sites Internet tiers, des
applications ou d'autres produits ou services (« Services tiers »). Drepacare ne
contrôle d'aucune manière les Services tiers et, par conséquent, n'assume
aucune responsabilité liée auxdits Services tiers. Vous devez prendre les
mesures pertinentes pour déterminer si l'accès à un Service tiers est approprié, y
compris la protection de vos informations personnelles et de votre vie privée lors
de l'utilisation de ces Services tiers et le respect des accords applicables.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">16. Vos commentaires</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Vos commentaires sur l'application sont les bienvenus. Sauf indication contraire
expresse, toutes les communications que vous nous envoyez ou que vous publiez
dans les boutiques d'applications sont réputées être soumises de manière non
confidentielle. Vous acceptez que nous puissions décider de publier un tel
contenu à notre propre discrétion. Vous acceptez de nous autoriser à utiliser
gratuitement ces contenus et à réviser, modifier, ajuster et changer
contextuellement, ou apporter toute autre modification que nous jugerons
appropriée.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">17. Droits d'exécution</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Nous ne sommes pas tenus de surveiller l'accès ou l'utilisation de l'application.
Cependant, nous nous réservons le droit de le faire à des fins d'exploitation et de
maintenance de l'Application, pour nous assurer de votre conformité au présent
Contrat et pour nous conformer aux exigences légales applicables. Nous pouvons
divulguer un comportement illégal aux autorités chargées de l'application des lois
et, conformément à une procédure légale valide, nous pouvons coopérer avec les
autorités chargées de l'application des lois pour poursuivre les utilisateurs qui
enfreignent la loi. Nous nous réservons le droit (sans obligation) de supprimer ou
de désactiver tout contenu publié sur l'Application ou l'accès à l'Application à tout
moment et sans préavis, et à notre seule discrétion, si nous déterminons que
votre contenu ou votre utilisation de l'Application est répréhensible ou en violation
du présent Contrat.</div>
<div style={{marginTop: '10px'}}>Drepacare n'assume aucune responsabilité envers les utilisateurs de l'Application
ou toute autre personne ou entité pour l'exécution ou la non-exécution des
activités susmentionnées.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">18. Modifications de l'application</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>De temps à autre et sans préavis, nous pouvons changer, étendre et améliorer
l'Application. Nous pouvons également, à tout moment, cesser d'exploiter
l'intégralité ou une partie de l'Application ou désactiver de manière sélective
certaines fonctionnalités de l'Application. Votre utilisation de l'Application ne vous
donne pas droit à la fourniture ou à la disponibilité continue de l'Application. Toute
modification ou élimination de l'Application ou de toute fonctionnalité particulière
sera faite à notre seule et absolue discrétion et sans obligation permanente ni
responsabilité envers vous.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">19. Dégagement de responsabilité</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Vous acceptez de défendre, de protéger et de dégager Drepacare, ses dirigeants,
administrateurs, employés, mandataires, concédants de licence et fournisseurs,
de toute responsabilité en cas de plainte, d'action, demande et règlement, y
compris, sans limitation, pour les frais juridiques et comptables raisonnables,
résultant de votre violation effective ou supposée du présent Contrat ou qui en
résulterait.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">20. Propriété</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>L’application Drepacare et le logo Drepacare sont des marques déposées de
Drepacare. Tout usage ou reproduction quelconque de Drepacare ou de son logo
requiert une autorisation expresse et préalable de Drepacare. Les Utilisateurs et
les Membres reconnaissent que Drepacare dispose sur l’application Drepacare , à
titre exclusif, de l’intégralité des droits de propriété intellectuelle et industrielle qui
s’y appliquent, et ce au sens du droit français et des législations internationales
applicables. Toute utilisation non expressément autorisée par Drepacare au sens
des présentes conditions générales est illicite conformément aux dispositions du 
Code de la propriété intellectuelle. Ainsi, il est notamment interdit au Membre de
procéder à :</div>
<div style={{marginTop: '10px'}}>toute reproduction par quelque moyen que ce soit de l’application Drepacare,
et/ou toute représentation, diffusion ou commercialisation de l'application
Drepacare, que ce soit à titre gracieux ou onéreux ;
toute forme d'utilisation, d'adaptation, de modification, de transformation,
d'arrangement de l’application Drepacare de quelque façon que ce soit aux fins de
conception, réalisation, diffusion ou commercialisation de services similaires ou
pour quelque autre raison que ce soit ;
toute transcription, directe ou indirecte, ou traduction dans d'autres langages de
l’application Drepacare</div>
<div>Toute utilisation de l’application Drepacare non conforme aux présentes
conditions générales d’utilisation et/ou toute détérioration, toute représentation,
reproduction, modification, mise à disposition quelconque d’un tiers, à quelque
titre que ce soit, gratuit ou onéreux, toute utilisation commerciale, totale ou
partielle, des différents éléments de Drepacare est en conséquence interdite et
expose son (ou ses) auteur(s) à des sanctions civiles et/ou pénales.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">21. Loi Applicable et juridiction</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Le siège de Drepacare étant basé en France, les présentes conditions générales
sont régies par la loi française. Les utilisateurs étrangers acceptent expressément
l’application de la loi française en utilisant Drepacare. En conséquence, les
Utilisateurs reconnaissent que :</div>
<div>
<ul>
    <li>les pratiques médicales évoquées au sein de Drepacare,</li>
    <li>les références citées au sein de Drepacare,</li>
    <li>et de manière générale, toute information qui y est diffusée et/ou
échangée, sont susceptibles de ne pas être cohérentes ou appropriées en dehors
de la France.</li>
</ul>
</div>
<div style={{marginTop: '10px'}}>Toute action en justice que vous pourriez intenter concernant votre utilisation de
l'Application doit être introduite dans l'année suivant la réclamation ou
l'événement à l'origine de cette action.</div>
<div style={{marginTop: '10px'}}>Si, pour quelque motif que ce soit, un tribunal compétent juge inapplicable une
disposition du présent Contrat ou une partie de celui-ci, celle-ci sera appliquée
dans la mesure maximale autorisée pour respecter les intentions du présent
Contrat et le reste du présent Contrat continuera de s'appliquer de plein effet. Une
version imprimée du présent Contrat est recevable dans les procédures judiciaires
ou administratives.</div>
<div style={{marginTop: '10px'}}>Aucune renonciation de la part de Drepacare à une modalité ou condition
énoncée dans le présent Contrat ne sera considérée comme une renonciation
ultérieure ou continue à cette modalité ou condition, ni à la renonciation à toute
autre modalité ou condition, et tout manquement de la part de la Drepacare à
exercer un droit ou une disposition en vertu du présent Contrat ne constituera pas
une renonciation à ce droit ou à cette disposition. Si une disposition du présent
Contrat est jugée sans effet, illégale ou inexécutable pour quelque raison que ce
soit par une cour de justice ou un autre tribunal de la juridiction compétente, cette
disposition sera supprimée ou limitée au strict minimum de manière à ce que les
autres dispositions du présent Contrat gardent leur plein effet et restent en
vigueur.</div>
<div style={{marginTop: '10px'}}>Lors de la résiliation, toutes les dispositions du présent Contrat, qui, par leur
nature, devraient survivre à la résiliation, survivront à la résiliation, y compris,
sans limitation, les dispositions relatives à la propriété, les exclusions de garantie
et les limitations de responsabilité.</div>
<div style={{marginTop: '10px'}}>Tous les différends entre les parties liés au présent Contrat feront l'objet d'un
règlement individuel et les parties ne consolideront ni ne demanderont un
traitement collectif pour tout différend, sauf accord préalable écrit des parties.</div>
<div style={{marginTop: '10px'}}>Nous avons le droit de refuser le service, de fermer des comptes et de modifier
les conditions d'éligibilité à tout moment.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">22. Procédures de notification et de retrait</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Si vous pensez que le matériel accessible sur ou à partir de l'Application enfreigne
vos droits d'auteur, vous pouvez demander le retrait de ce matériel (ou l'accès à
celui-ci) de cette Application en contactant Drepacare et en fournissant les
informations suivantes :</div>
<div style={{marginTop: '10px'}}>а. Identification de l'œuvre protégée qui, selon vous, a été compromise. Veuillez
décrire l’œuvre et, si possible, inclure une copie ou l'emplacement (par exemple,
la page de l'Application) d'une version autorisée de l'œuvre.</div>
<div style={{marginTop: '10px'}}>b. Identification du document qui selon vous est à l'origine de la violation et son
emplacement. Veuillez décrire le document et nous fournir son URL ou toute autre
information pertinente qui nous permettra de le localiser.</div>
<div style={{marginTop: '10px'}}>c. Votre nom, adresse, numéro de téléphone et adresse de messagerie
électronique (si disponible).</div>
<div style={{marginTop: '10px'}}>d. Une déclaration selon laquelle vous croyez de bonne foi que l'utilisation des
documents visés par la plainte n'est pas autorisée par le détenteur des droits
d'auteur, son agent ou la loi.</div>
<div style={{marginTop: '10px'}}>e. Une déclaration selon laquelle les informations que vous avez fournies sont
exactes et qui indique « sous peine de parjure » que vous êtes le titulaire du droit
d'auteur ou que vous êtes autorisé à agir en son nom.</div>
<div style={{marginTop: '10px'}}>f. Une signature ou son équivalent électronique émanant du détenteur des droits
d'auteur ou de son représentant autorisé.</div>
<div style={{marginTop: '10px'}}>Afin de protéger les droits des titulaires de droits d'auteur, Drepacare maintient
une politique de résiliation, dans des circonstances appropriées, des abonnés et
des titulaires de compte de l'Application qui sont des récidivistes.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">23. Cookies</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Il y est fait usage de cookies à l’exclusion du Service de conseil en ligne.
Un cookie est un petit fichier alphanumérique qui est déposé dans le terminal de
l’ordinateur de l’Utilisateur, lors de sa connexion à l’Application, après son
consentement exprès à ce titre. </div>
<div style={{marginTop: '10px'}}>Les cookies utilisés dans le cadre de l’Application ont pour finalité exclusive :</div>
<div>- d’améliorer la navigation au sein de l’Application afin de pouvoir en utiliser les
différentes fonctionnalités, et notamment l’identification et l’accès au Compte
personnel ;</div>
<div>- d’établir des statistiques d’usage des divers éléments composant l’Application
(rubriques et contenus visités, parcours, date et heure de consultation...) ;</div>
<div style={{marginTop: '10px'}}>A ce titre, un bandeau d’information s’affiche lors de la connexion de l’Utilisateur à
la l’Application, afin de l’informer préalablement au dépôt de ces cookies :</div>
<div style={{marginTop: '10px'}}>- des finalités précises des cookies utilisés ;</div>
<div>- de son droit d’accès aux données collectées ;</div>
<div>- de la possibilité de s’opposer au dépôt de cookies pour l’analyse de sa
navigation, et du fait que la poursuite de la navigation vaut accord au dépôt de
cookies sur son terminal ;</div>
<div>- des modalités lui permettant de changer les paramètres (paramétrage interne,
opposition au traçage, ou encore paramétrage du navigateur), au titre du dépôt de
cookies ayant pour finalité d’accéder à des informations déjà stockées dans son
terminal, le cas échéant.</div>
<div>Via le bandeau, l’Utilisateur est invité à exprimer son consentement au titre du
dépôt de cookies ayant pour finalité d’accéder à des informations déjà stockées
dans son terminal, le cas échéant.</div>
<div>Le dépôt de cookies réalisé dans le cadre de l’utilisation de l’Application ne
permet pas d’identifier l’Utilisateur personnellement mais il enregistre des
informations relatives à la navigation de son terminal au sein de l’Application que
Drepacare pourra lire lors des visites ultérieures de l’Utilisateur au sein de
l’Application.</div>
<div>Les informations collectées sont à l’usage exclusif de Drepacare ou de ses
prestataires techniques, et ne sont en aucun cas cédées à des tiers.</div>
<div style={{marginTop: '10px'}}>En savoir plus sur les cookies en se rendant sur le site: https://www.cnil.fr/fr/siteweb-cookies-et-autres-traceurs</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">Questions et commentaires</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Pour tous commentaires ou questions sur une partie de l'Application ou une partie
des présentes Conditions d'utilisation, si vous avez besoin d'assistance ou si vous
avez des réclamations à faire, veuillez nous contacter à contact@drepacare.com</div>
</div>
<div className="Title">Mentions légales et Politique de confidentialité</div>
            <div className="Form">
                <div>Date de la publication : 8 juin 2021</div>
                <div>Date de la dernière mise à jour : 10 octobre 2021</div>
                <div style={{height: '30px'}}></div>
                
<div style={{marginTop: '10px'}}>Lors de votre utilisation de Drepacare vous nous confiez des informations
intimes et personnelles. Nous nous engageons à préserver ce lien de confiance
entre nous. C'est pourquoi notre politique est de tout mettre en œuvre afin de
garantir la protection des données et des droits à la vie privée de chaque
utilisateur et d'assurer la transparence concernant nos pratiques en matière de
données. </div>
<div style={{marginTop: '10px'}}>L'objectif principal de notre Politique de confidentialité est de vous fournir des
informations claires sur quelles données nous collectons, comment elles sont
utilisées et partagées et comment vous pouvez les contrôler.</div>
<div style={{marginTop: '10px'}}>Nous vous recommandons de lire la présente Politique de confidentialité dans
son intégralité ainsi que nos conditions d'utilisation dont voici quelques points
clés que, nous l'espérons, vous trouverez utiles.</div>
<div style={{marginTop: '10px'}}>Lors de votre utilisation de Drepacare, nous pouvons collecter vos Données à
caractère personnel et les utiliser à des fins d'amélioration de l'expérience
utilisateur, telles qu'une plus grande personnalisation des articles que vous
recevez, etc. Aux fins des activités de recherche, nous utilisons uniquement des
données anonymisées et agrégées, qui ne peuvent pas vous être associées.</div>
<div style={{marginTop: '10px'}}>Vous pouvez contribuer au développement de la communauté de Drepacare
Sous réserve de votre consentement, nous pouvons utiliser les informations
techniques vous concernant (votre identifiant technique unique, votre tranche
d'âge, le statut de votre abonnement et les données relatives au lancement de
l'application) à des fins de promotion afin de toucher davantage de personnes
comme vous. Vous pouvez retirer votre consentement au partage de ces
données à tout moment.</div>
<div style={{marginTop: '10px'}}>Nous nous réservons le droit de modifier cette Politique de confidentialité de
temps à autre. Si nous apportons des modifications importantes, vous en serez
informé par courriel (envoyé à l'adresse électronique indiquée lors de votre
inscription), par l'application ou en vous présentant une nouvelle version de la
présente Politique de confidentialité. Si vous continuez à utiliser l'Application
après la date d'entrée en vigueur d'une version actualisée de la Politique de
confidentialité cela vaudra acceptation de la Politique de confidentialité telle
qu'elle a été modifiée. Vous devrez parfois accepter explicitement les
modifications apportées à la Politique de confidentialité. Pour prendre
connaissance des dernières mises à jour concernant nos pratiques en matière
de confidentialité des données, veuillez consulter notre Site Web et
l'Application. Si vous n'acceptez pas les termes de la Politique de
confidentialité, nous vous demandons de ne pas utiliser l'Application. Pour nous
signifier votre refus des termes de la présente Politique de confidentialité,
veuillez quitter l'Application immédiatement.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">1. Dans quel cadre vos données sont-elles collectées ?</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px', fontWeight: 'bold'}}>Drepacare est dédié aux patients et à leurs proches souhaitant s’informer
et avoir un suivi personnalisé. </div>
<div style={{marginTop: '10px', fontWeight: 'bold'}}>Les professionnels de la santé et les associations peuvent également
s’inscrire afin de s’informer, parcourir l’application, et la proposer à leurs
patients et/ou adhérents.</div>
<div style={{marginTop: '10px', fontWeight: 'bold'}}>Un ensemble de fonctionnalités est proposé aux utilisateurs inscrits sur
l’Application:</div>
<div style={{marginTop: '10px'}}>
    <ul>
        <li>Espace de suivi de l’actualité: articles, témoignages, guides, répertoires,
évènements</li>
        <li>Chat téléconseils: échanges sur sa pathologie avec des professionnels
de santé et des professionnels de la drépanocytose;</li>
        <li>Profil avec carte d’urgence digitale : espace des principaux indicateurs
de sa pathologie (exemples : hémoglobine, poids, taille, groupe sanguin,
vaccin, type de drépanocytose, etc …). ;</li>
        <li>Statistiques et graphiques santé : synthèse graphique des éléments
renseignés à imprimer et à partager avec son médecin lors de la consultation
médicale;</li>
        <li>Traitements : saisie de ses traitements médicamenteux en cours avec
possibilité d’indiquer les effets indésirables éventuels et de donner son avis;</li>
        <li>Rendez-vous médicaux : système automatisé de rappel de ses rendezvous médicaux ;</li>
        <li>Hydratation: enregistrer son objectif hydrique journalier, avoir des
rappels</li>
        <li>Douleur: enregistrer ses douleurs, seuil, localisation etc</li>
        <li>Hospitalisation: enregistrer ses hospitalisations, ses transfusions</li>
        <li>Urgence: envoyer un message géolocalisé à ses proches en cas de
crise</li>
        <li>Etudes, recherches, enquêtes : possibilité de répondre à des
questionnaires proposés par Drepacare pour son compte ou pour le compte de
ses partenaires contractuels.</li>
<li>Forums de discussion : partage et échanges sur sa pathologie avec
l’ensemble des membres de la communauté dans plusieurs groupes à thème.</li>
    </ul>
</div>
<div style={{marginTop: '10px'}}>Données à caractère personnel que vous nous fournissez directement
Informations générales. Lorsque vous vous enregistrez pour utiliser
l'Application, nous pouvons collecter des Données à caractère personnel vous
concernant telles que :</div>
<div>nom ; </div>
<div>prénom, </div>
<div>adresse électronique ;</div>
<div>genre ;</div>
<div>date de naissance ;</div>
<div>mot de passe ;</div>
<div>lieu de résidence et informations de localisation associées ;</div>
<div>identifiant (à des fins limitées).</div>
<div>Santé et bien-être. Lors de votre utilisation de l'Application, vous pouvez choisir
de communiquer des informations personnelles sur votre santé et votre bienêtre, telles que :</div>
<div>poids, taille;</div>
<div>informations liées à votre pathologie;</div>
<div>symptômes divers liés à votre santé ;</div>
<div>autres informations sur votre santé, votre bien-être et vos activités connexes, y
compris votre vie personnelle (dénommées collectivement « Données à
caractère personnel »).</div>
<div style={{marginTop: '10px'}}>Données à caractère personnel que nous pouvons collecter automatiquement
Lorsque vous vous connectez ou utilisez l'Application, nous pouvons collecter
automatiquement les informations suivantes.</div>
<div style={{marginTop: '10px'}}>Des informations sur l'appareil :</div>
<div>le modèle de l'appareil ;</div>
<div>les informations relatives au système d'exploitation et à sa version ;</div>
<div>les identifiants uniques de l'appareil (par exemple IDFA) ;</div>
<div>des informations sur le réseau mobile ;</div>
<div>des informations relatives au stockage de l'appareil.</div>
<div>Informations de localisation</div>
<div style={{marginTop: '10px'}}>adresse IP ;</div>
<div>fuseau horaire ;</div>
<div>informations sur votre fournisseur de services mobiles.</div>
<div style={{marginTop: '10px'}}>Données relatives à l'utilisation de l'Application, notamment :</div>
<div style={{marginTop: '10px'}}>la fréquence d'utilisation ;</div>
<div>les sections et fonctionnalités de notre Application que vous visitez ;</div>
<div>vos modes d'utilisation en général ;</div>
<div>votre utilisation de fonctionnalités particulières.</div>
<div>Pour collecter ces informations, nous pouvons également envoyer des cookies
sur votre appareil mobile ou votre ordinateur, ou utiliser d'autres technologies
de suivi.</div>
<div style={{marginTop: '10px'}}>IMPORTANT : Avant de devenir membre de Drepacare vous devez prendre
connaissance des conditions dans lesquelles vos données à caractère
personnel vont être collectées, traitées, utilisées et hébergées, ainsi que de vos 
droits et obligations en tant que Membre bénéficiant des services proposés par
Drepacare.</div>
<div style={{marginTop: '10px'}}>Pour information, l'adresse email peut permettre d'identifier votre adresse IP et
éventuellement vous géolocaliser. Drepacare s'engage à utiliser l'adresse email
uniquement pour contacter ses utilisateurs.</div>
<div style={{marginTop: '10px'}}>Pour vous permettre de vous inscrire en qualité de Membre de Drepacare sont
nécessaires vos consentements actifs et volontaires :</div>
<div style={{marginTop: '10px'}}>
    <ul>
        <li>aux conditions générales d’utilisation de Drepacare</li>
        <li>et au traitement de vos données de santé à caractère personnel pour
vous permettre de bénéficier des services exposés ci-dessus.</li>
    </ul>
</div>
<div style={{marginTop: '10px'}}>
<table className="tableStyle">
  <thead>
    <tr>
      <th scope="col">Finalité</th>
      <th scope="col">Base juridique</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>L'analyse, l'exploitation, le
maintien et l'amélioration de
l'Application, l'ajout de nouvelles
fonctionnalités et de nouveaux
services à l'Application, la prise
en charge des fonctions
existantes de celle-ci</td>
      <td>Consentement</td>
    </tr>
    <tr>
      <td>La personnalisation du contenu et
des documents qui vous sont
affichés lorsque vous utilisez
l'application.</td>
      <td>Consentement</td>
    </tr>
    <tr>
      <td>La personnalisation des offres et
des recommandations de produits
et de services qui vous sont
destinées, y compris les produits
et les offres de tiers</td>
      <td>Consentement</td>
    </tr>
    <tr>
      <td>La fourniture et livraison des
services et des produits que vous
demandez, le traitement des
transactions et l'envoi des
informations afférentes, y compris les confirmations et les rappels.</td>
      <td>Contrat</td>
    </tr>
    <tr>
      <td>La facturation, la gestion des
comptes et d'autres fins
administratives, le cas échéant.</td>
      <td>Contrat</td>
    </tr>
    <tr>
      <td>Les réponses à vos
commentaires, à vos questions et
à vos demandes ainsi que la
fourniture du service client.</td>
      <td>Contrat</td>
    </tr>
    <tr>
      <td>L'envoi d'avis techniques, de
mises à jour, d'alertes de sécurité
et de messages d'assistance et
administratifs.</td>
      <td>Intérêt légitime</td>
    </tr>
    <tr>
      <td>La surveillance et l'analyse des
tendances, de l'utilisation et des
activités en lien avec notre
application.</td>
      <td>Consentement</td>
    </tr>
    <tr>
      <td>Répondre à vos demandes
d’information en matière de
données à caractère personnel</td>
      <td>Obligation légale</td>
    </tr>
    <tr>
      <td>La promotion de la société : cela
concerne uniquement les
informations que vous avez
accepté de partager</td>
      <td>Consentement</td>
    </tr>
    <tr>
      <td>Gérer les impayés et les
contentieux éventuels quant à
l’utilisation de nos produits et
services,</td>
      <td>Exécution du contrat</td>
    </tr>
    <tr>
      <td>La vérification de votre identité</td>
      <td>Obligation légale</td>
    </tr>
    <tr>
      <td>Gérer votre accès à certains
services accessibles dans
l'Application, ainsi que leur
utilisation</td>
      <td>Exécution du contrat</td>
    </tr>
    <tr>
      <td>Gérer la gestion des avis des
personnes sur des produits,
services ou contenus</td>
      <td>Intérêt légitime</td>
    </tr>
    <tr>
      <td>Effectuer les opérations relatives
à la gestion des clients
concernant les contrats,
commandes, livraisons, factures,
programmes de fidélité, suivis de
la relation avec les clients,</td>
      <td>Exécution du contrat</td>
    </tr>
    <tr>
      <td>Mise en relation avec un
professionnel de santé et gestion
d’un dossier médical</td>
      <td>Exécution du contrat et
transmission nécessaire à une
activité médicale</td>
    </tr>
    <tr>
      <td>Élaborer des statistiques
commerciales et de fréquentation
de nos services</td>
      <td>Intérêt légitime (consentement
pour les Cookies)</td>
    </tr>
    <tr>
      <td>Envoi d'informations sur les
Services ou en lien avec la Santé
par Drepacare (notamment
prospection commerciale)</td>
      <td>Intérêt légitime</td>
    </tr>
    <tr>
      <td>Envoi d'informations sur les
Services ou en lien avec la santé
par des tiers</td>
      <td>Intérêt légitime</td>
    </tr>
    <tr>
      <td>Respecter nos obligations légales</td>
      <td>Obligation légale</td>
    </tr>
    <tr>
      <td>Fourniture du Service de conseil
en ligne</td>
      <td>Exécution des Conditions
Générales d’utilisation et de vente</td>
    </tr>
  </tbody>
</table>
</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">2. A quoi vont servir vos données ?</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Vos données peuvent, sous réserve de votre consentement explicite à ce titre,
faire l’objet des usages ci-après :</div>
<div style={{marginTop: '10px'}}>Vous permettre de bénéficier des services qui vous intéressent et
auxquels vous souhaitez vous inscrire à savoir :</div>
<div style={{marginTop: '10px'}}>
    <ul>
        <li>La diffusion et le partage d’informations et de contenus divers relatifs à
la drépanocytose, à la santé, à la forme ainsi qu’à d’autres affections et
pathologies, et à tous produits et/ou prestations s’y rapportant vous intéressant,</li>
        <li>L’échange d’informations, de messages et de contenus dans le chat
téléconseils avec les professionnels de la santé et de la drépanocytose de
Drepacare </li>
        <li>Le bénéfice d’outils de suivi et prévention individuel de votre état de
santé,</li>
    </ul>
</div>
<div style={{marginTop: '10px'}}>Vous êtes ainsi informé que dans ce cadre des données à caractère
personnel – en particulier des données d’identification et des données de santé
– vous concernant sont collectées et traitées sur la base de votre consentement
explicite et en exécution des conditions générales d’utilisation de Drepacare. </div>
<div style={{marginTop: '10px'}}>Vos données pourront également être traitées pour vous permettre de
recevoir de l’information institutionnelle ou promotionnelle de Drepacare ou de
ses partenaires via votre fil d’actualité sur l’application et par mail sur les sujets
qui vous intéressent sur la base d’un consentement volontaire, actif et
spécifique de votre part. Vous pourrez par exemple recevoir de l’information sur
des études et enquêtes afin de vous permettre d’y participer si vous le
souhaitez et /ou d’être mis en relation vers les organismes chargés de la
réalisation de telles études et/ou enquêtes.</div>
<div style={{marginTop: '10px'}}>Vous êtes informé que vous pouvez retirer vos consentements à tout moment
et sans justification. Dans ce cas, vous reconnaissez que vous ne pourrez plus
bénéficier des services accessibles à partir de Drepacare et/ou recevoir des
informations institutionnelles et promotionnelles.</div>
<div style={{marginTop: '10px'}}>Sachez que le retrait de votre consentement ne portera pas atteinte à la licéité
du traitement effectué avant le retrait de votre consentement.</div>
<div style={{marginTop: '10px'}}>Vos données pourront également être exploitées dans le cadre d’études ou
d’évaluation dans le domaine de la santé notamment sémantique, scientifique,
médicale, médico-économique, ou de qualité de vie mises en œuvre par
Drepacare pour son compte ou pour le compte de ses partenaires contractuels,
sauf opposition spécifique de votre part pour chaque étude portée à votre 
connaissance au préalable, et après mise en œuvre de l’ensemble des
formalités requises auprès de la CNIL lorsqu’elles sont applicables.</div>
<div style={{marginTop: '10px'}}>Les études sont conduites après l’application de mesures de sécurité
renforcées afin de garantir le respect de votre vie privée.</div>
<div style={{marginTop: '10px'}}>Enfin, Drepacare peut également produire des statistiques dans le cadre de ses
activités et des services qu’elle met en œuvre après l’agrégation de vos
données ne permettant plus de vous identifier et afin de garantir votre
anonymat.</div>
<div style={{marginTop: '10px'}}>Selon le cas, nous traiterons vos Données à caractère personnel sur la base
juridique suivante : </div>
<div style={{marginTop: '10px', fontWeight: 'bold'}}>Votre consentement</div>
<div style={{marginTop: '10px'}}>L'autorisation de traitement de vos Données à caractère personnel que vous
accordez sur l'écran d'enregistrement ou à tout autre moment approprié, le cas
échéant ; </div>
<div style={{marginTop: '10px', fontWeight: 'bold'}}>Vos relations contractuelles avec nous</div>
<div style={{marginTop: '10px'}}>Nous pouvons être amenés à devoir traiter certaines de vos Données à
caractère personnel pour rendre l'accès à l'Application et à ses services
disponibles, ainsi que leur installation et utilisation ultérieure ;</div>
<div style={{marginTop: '10px', fontWeight: 'bold'}}>Intérêt légitime</div>
<div style={{marginTop: '10px'}}>Nous pouvons traiter certaines de vos Données à caractère personnel dans le
but d'atteindre des intérêts commerciaux et des avantages sociétaux plus
larges ;</div>
<div style={{marginTop: '10px', fontWeight: 'bold'}}>Obligation légale</div>
<div style={{marginTop: '10px'}}>Nous pouvons nous trouver dans l'obligation de traiter certaines de vos
Données à caractère personnel pour nous conformer aux lois et règlements
applicables. </div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">3. Qui est responsable des traitements de vos données ?</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Au sens de la Loi 78-17 du 6 janvier 1978 modifiée relative à l’Informatique, aux
Fichiers et aux Libertés et du Règlement UE 2016/679 relatif à la protection des
personnes physiques à l’égard du traitement des données à caractère
personnel et à la libre circulation de ces données du 27 avril 2016 (le tout
désigné la « Réglementation »), les personnes morales suivantes sont
respectivement responsables des traitements de vos données pour ce qui les
concerne :</div>
<div style={{marginTop: '10px'}}>
    <ul>
        <li>L’association Drepacare en tant qu’éditeur de l’application Drepacare
pour la fourniture des services présentés ci-dessus </li>
        <li>En l’absence d’opposition de votre part, pour chaque étude ou
évaluation dans le domaine de la santé notamment sémantique, scientifique,
médicale, médico-économique, ou de qualité de vie portée à votre
connaissance au préalable, par tout promoteur à savoir Drepacare ou ses
partenaires contractuels.</li>
    </ul>
</div>
<div style={{marginTop: '10px'}}>Drepacare recourt à des sous-traitants appliquant des garanties suffisantes au
titre de la mise en œuvre de mesures techniques et organisationnelles
appropriées, afin d’assurer le respect des exigences de confidentialité, de
sécurité et de pérennité de ces données.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">4. A qui sont destinées vos données et combien de temps
sont-elles conservées ?</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Vos données à caractère personnel et notamment vos données de santé sont
strictement destinées à Drepacare ainsi qu’aux seuls membres que vous allez
autoriser spécifiquement à cet effet, au fur et à mesure de votre utilisation des
services de Drepacare.</div>
<div style={{marginTop: '10px'}}>Aux administrateurs strictement habilités de l’hébergeur agréé/certifié de
données de santé, au sens de l’article L.1111-8 du Code de la santé publique,
dans la limite de leurs attributions respectives ;</div>
<div style={{marginTop: '10px'}}>Aux personnes habilitées au titre des tiers autorisés par la loi
Dans le respect des règles relatives au secret médical et au partage des
Données de santé couvertes par le secret médical, les informations concernant
la santé les Utilisateurs Patients sont destinées exclusivement:</div>
<div style={{marginTop: '10px'}}>
    <ul>
        <li>Aux Médecins téléconsultants et aux Professionnels choisis par l’Utilisateur
Patient pour lui délivrer un Service ;</li>
        <li>Aux Utilisateurs Professionnels de santé et autres professionnels
spécifiquement habilités dans le strict respect de leurs missions;</li>
        <li>Aux membres de l'équipe de soins de l'Utilisateur Patient au sens des
dispositions légales et règlementaires applicables;</li>
        <li>Le cas échéant et avec son consentement, au médecin traitant déclaré par
l’Utilisateur Patient. </li>
    </ul>
</div>
<div style={{marginTop: '10px'}}>Le cas échéant, vos données sont destinées aux promoteurs des études et
évaluation en santé, sous réserve de votre opposition spécifique à chaque
étude portée au préalable à votre connaissance.</div>
<div style={{marginTop: '10px'}}>Drepacare garantit que vos données à caractère personnel ne feront pas l’objet
d’un transfert vers un pays tiers à l’Union européenne.</div>
<div style={{marginTop: '10px'}}>Drepacare garantit que vos données à caractère personnel et notamment vos
données de santé à caractère personnel ne seront transmises à aucun tiers non
autorisé.</div>
<div style={{marginTop: '10px'}}>Si vous supprimez votre compte ou si vous ne renouvelez pas votre
abonnement, vos messages resteront visibles par l’équipe du Chat Téléconseils
et par Drepacare.</div>
<div style={{marginTop: '10px'}}>Vos données au titre de l’utilisation des services proposés via Drepacare sont
conservées pendant toute la durée nécessaire à l’utilisation desdits services
pendant 5 ans en base active et 15 ans en base archive.</div>
<div style={{marginTop: '10px'}}>Si Drepacare constate que votre compte personnel est demeuré inactif pendant
5 ans, Drepacare vous adressera un message vous informant de la clôture de
votre compte et de la suppression de vos données à caractère personnel.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">5. Quels sont les droits dont vous disposez sur vos
données ?</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px', fontWeight: 'bold'}}>Vous avez le contrôle</div>
<div style={{marginTop: '10px'}}>Vous pouvez accéder à vos Données à caractère personnel, les modifier, les
corriger, les effacer et les mettre à jour. Vous pouvez également télécharger les
informations vous concernant collectées par Drepacare en nous contactant.
Veuillez noter que le fait de supprimer ou de modifier certaines Données à
caractère personnel que vous avez entrées peut affecter votre utilisation de
Drepacare à l'avenir.</div>
<div style={{marginTop: '10px'}}>Conformément à la réglementation précitée vous disposez :</div>
<div style={{marginTop: '10px'}}>
    <ul>
        <li>d’un droit d'accès et de rectification des données à caractère personnel
vous concernant ;</li>
        <li>d’un droit d’opposition et de suppression de vos données à caractère
personnel ;</li>
        <li>d’un droit de communiquer vos directives concernant le sort de vos
données à caractère personnel après votre mort ;</li>
    </ul>
</div>
<div style={{marginTop: '10px'}}>Vous disposez également :</div>
<div style={{marginTop: '10px'}}>
    <ul>
        <li>du droit de retirer votre consentement à tout moment ;</li>
        <li>du droit de solliciter une limitation du traitement ;</li>
        <li>d’un droit à l’oubli et à l’effacement numérique ;</li>
        <li>d’un droit à la portabilité de vos données ;</li>
        <li>du droit d’introduire une réclamation auprès de la CNIL.</li>
    </ul>
</div>
<div style={{marginTop: '10px'}}>Pour exercer vos droits, vous pouvez vous adresser, en fournissant une copie
d’un justificatif de votre identité, à Drepacare à l’adresse électronique suivante :
contact@drepacare.com.</div>
<div style={{marginTop: '10px'}}>Vous pouvez également vous adresser au délégué à la protection des données
de Drepacare pour toute question concernant vos données à caractère
personnel, aux coordonnées suivantes : dpo@drepacare.com.</div>
<div style={{marginTop: '10px'}}>Comment exercer vos droits en matière de protection de la vie privée
Pour exercer l'un de vos droits en matière de protection de la vie privée,
contactez-nous à l'adresse : contact@drepacare.com</div>
<div style={{marginTop: '10px'}}>Nous agirons en conséquence dans les 30 jours suivant la réception. Dans
certains cas, cela peut prendre jusqu'à 90 jours, par exemple pour l'effacement
complet de vos Données à caractère personnel stockées dans nos systèmes de
sauvegarde. Si plus de temps nous est nécessaire, nous vous en informerons
et vous expliquerons les raisons du délai. </div>
<div style={{marginTop: '10px'}}>Veuillez garder à l'esprit que si la demande reçue manque de clarté, nous
pouvons demander à la personne de la préciser pour mieux en comprendre la
motivation et le contenu. Nous pouvons également refuser des demandes
manifestement sans fondement et excessives (répétitives). </div>
<div style={{marginTop: '10px'}}>Dans certains cas, nous pouvons également vous demander de prouver votre
identité. Généralement, nous nous assurons de vérifier que la demande
provient de la même adresse e-mail que celle que vous avez indiquée lors de
votre inscription à l'application. En cas de doute, nous pouvons vous demander
de vous soumettre à une vérification supplémentaire. Ceci, dans le but de nous
assurer que votre demande ne porte atteinte à aucun droit de tiers.</div>
<div style={{marginTop: '10px'}}>Sous réserve des lois applicables, vous avez le droit de déposer une plainte
auprès de votre autorité locale compétente en matière de protection des
données à propos de l'une ou plusieurs de nos activités (par rapport à vos
droits à la vie privée, entre autres) que vous jugez non conformes à la loi en
vigueur. Cependant, si à votre avis, nous faisons quelque chose que nous ne
devrions pas faire, dans un premier temps, informez-nous de la situation à
l'adresse : contact@drepacare.com. Votre vie privée est notre priorité et nous
tenons à nous assurer que nous avons tout fait pour répondre à vos
préoccupations, quelles qu'elles soient.</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">6. Vos données sont en sécurité avec Drepacare</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Tant que vous ne leur en parlerez pas, ni votre employeur, ni votre compagnie
d’assurance, ni même vos proches ne sauront jamais rien sur les informations
que vous enregistrez ou que vous obtenez grâce à Drepacare. Nous prenons
toutes les mesures raisonnables et appropriées pour protéger vos Données à
caractère personnel contre la perte, le vol, l'utilisation abusive ou l'accès non
autorisé.</div>
<div style={{marginTop: '10px'}}>En vertu du RGPD, vos données à caractères personnelles et de santé sont
stockées et sauvegardées en toute sécurité sur notre hébergeur certifié HDS
(Hébergement de Données de Santé agréés).</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">7. Communication avec vous</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Nous pouvons être amenés à vous contacter de temps à autre par courriel ou
par d'autres moyens (des popups ou des notifications push, par exemple) pour
vous donner des informations sur des produits, des services, des offres, des
promotions, des récompenses et des événements que nous et d'autres
proposons, ainsi que pour vous faire part de nouveautés et de renseignements
qui, à notre avis, pourraient vous intéresser. </div>
<div style={{marginTop: '10px'}}>Options de retrait. </div>
<div>Vous pouvez à tout moment refuser de recevoir des courriers électroniques en
vous désabonnant à l'aide du lien « Se désabonner » contenu dans le courrier
électronique. Le fait de choisir de ne plus recevoir ces courriels ou ces
notifications ne mettra pas fin à la transmission des courriels importants liés au
service, qui sont nécessaires à votre utilisation de l'Application. Si les lois
applicables l'exigent, nous pouvons demander à certaines utilisatrices de
donner leur consentement aux communications de ce type.</div>
<div style={{marginTop: '10px'}}>Veuillez noter que nous pouvons être amenés à vous contacter pour vous
donner des informations sur des produits, des services, des offres, des
promotions, des récompenses et des événements que nous et d'autres
proposons par le biais de plateformes tierces (comme les réseaux
sociaux)..Veuillez noter que vous pouvez à tout moment décider de ne plus
recevoir ces informations et demander que vos Données à caractère personnel
ne soient plus utilisées en nous contactant à l'adresse :
contact@drepacare.com</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">8. Délégué à la protection des données (DPD)</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Pour communiquer avec notre délégué à la protection des données, veuillez
envoyer un courriel à l'adresse : dpo@drepacare.com</div>
<div style={{height: '30px'}}></div>
                <div className="TitleSection">9. Nous contacter</div>
                <div style={{height: '30px'}}></div>
<div style={{marginTop: '10px'}}>Généralités</div>
<div style={{marginTop: '10px'}}>Pour toute question ou préoccupation concernant le respect de votre vie privée,
vous pouvez nous contacter à :</div>
<div style={{marginTop: '10px'}}>Drepacare</div>
<div>3 Allée Jean Sébastien Bach 94140 Alfortville</div>
<div>E-mail : contact@drepacare.com</div>
<div style={{marginTop: '20px', fontSize: 25}}>Vous pouvez nous parler librement</div>
<div style={{marginTop: '10px'}}>Nous croyons en un dialogue transparent et ouvert. C'est pourquoi, pour toute
question relative à la présente politique, à la façon dont nous collectons ou
traitons vos Données à caractère personnel ou à tout ce qui a trait à nos
pratiques de confidentialité, nous vous encourageons vivement à contacter
notre équipe d'assistance, notre délégué à la protection des données ou à
envoyer un message à notre adresse électronique dédiée.</div>
<div style={{marginTop: '10px'}}></div>
                <div style={{height: '50px'}}></div>
            </div>
            <div className="FooterBorder"></div>
            <img className="FooterImgLogo" src={footerLogo} alt="header" />
        </div>
    );
}

export default FormScreen;
