import header from './../img/logo-drepacare.png';
import footerLogo from './../img/logo-drepacare.png';
import imgTopLeft from './../img/img_top-left.png';
import imgBottomRight from './../img/img_bottom-right.png';
import './Form.css';
import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from "react-router";
import QuestionRadioGroup from './QuestionRadioGroup'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { firebaseApp, firestore } from '../helpers/firebase'
import { useLocation } from "react-router-dom";
import { sendMail } from '../helpers/mail';

const Step2Screen = ({history}) => {
    const location = useLocation();
    
        const emailRef = useRef("")
        const nameRef = useRef("")

    const commentRef = useRef('')
    const suggestionRef = useRef('')
    const [documentId, setDocumentId] = useState(null)

    const [emailValid, setEmailValid] = useState(true)
    const [nameValid, setNameValid] = useState(true)
    const [telephoneValid, setTelephoneValid] = useState(true)

    useEffect(() => {

        const query = new URLSearchParams(window.location.search);
        setDocumentId(query.get('id'))
        if (documentId === null){
            return <Redirect to="/" />;
        }
    }, [documentId])

    const validateEmail = (email) => {
        const format = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return format.test(String(email).toLowerCase());
    }

    const sendButtonClicked = () => {

        const email = emailRef?.current?.value
        const name = nameRef?.current?.value

        setEmailValid(email.length > 0 && validateEmail(email))
        setNameValid(name.length > 0)
    
        if (email.length === 0 || name.length === 0 || !validateEmail(email)){
            console.log('Missing infos')
            return
        }

        const comment = commentRef?.current?.value

        const inputData = {
            form: 'Contact Drepacare',
            email,
            name,
        }
        
        const mailData = {
            ...inputData,
            comment,
        }
        sendMail(mailData)
        history.push("/thanks")
    }

    const CustomButton = withStyles({
        root: {
            textTransform: 'none',
            width: 150,
            height: 45,
            fontSize: 16,
            color: '#0078C1',
            border: '2px solid',
            borderRadius: 5,
            fontFamily: 'Neo Sans Pro Regular',
            backgroundColor: '#FFFFFF',
            borderColor: '#0078C1',
            '&:hover': {
                backgroundColor: '#0078C1',
                borderColor: '#0078C1',
                color: '#FFFFFF',
                boxShadow: 'none',
            },
        },
      })(Button);

    return (
        <div className="Container">
            <img className="HeaderImg" src={header} alt="header" />
            <div className="Form">
                <div className="Mandatory">*Obligatoire</div>
                <div className="FormCell FormCellNoBorderBottom FormCellRoundTop">
                    <div className="FormLeft">
                        <div className="FormLeftTitle" style={{color: '#1E76BD'}}>Coordonnées</div>
                        <div className="FormLeftDescription">Merci de saisir vos coordonnées</div>
                    </div>
                    <div className="FormRight PaddingLeftRight">
                        <div className="Overlay">
                            <img className="ImgTopLeft" src={imgTopLeft} alt="imgTopLeft" />
                        </div>
                        <div style={{height: '30px'}}></div>
                        <div className="FormRight PaddingLeftRight">
                        <div className="TextFieldForm">
                            <div style={{height: '30px'}}></div>
                            <div className="TextFieldTitle" >Adresse e-mail <span style={{color: '#FF0000'}}>*</span></div>
                            {emailValid ? 
                                <TextField className="TextField" variant="outlined" size="small" inputRef={emailRef}/> : 
                                <TextField error className="TextField" variant="outlined" size="small" inputRef={emailRef}/> 
                            }
                            {!emailValid ? <span className="ErrorMessage">Champ obligatoire ou format incorrect</span> : null}
                            <div className="TextFieldTitle">Votre nom & prénom <span style={{color: '#FF0000'}}>*</span></div>
                            {nameValid ? 
                                <TextField className="TextField" variant="outlined" size="small" inputRef={nameRef}/> :
                                <TextField error className="TextField" variant="outlined" size="small" inputRef={nameRef}/>
                            }
                            {!nameValid ? <span className="ErrorMessage">Champ obligatoire</span> : null }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="FormCell FormCellNoBorderBottom FormCellRoundTop">
                    <div className="FormLeft">
                        <div className="FormLeftTitle" style={{color: '#1E76BD'}}>Message</div>
                        <div className="FormLeftDescription">Merci de saisir votre message</div>
                    </div>
                    <div className="FormRight PaddingLeftRight">
                        <div style={{height: '30px'}}></div>
                        <div className="FormRight PaddingLeftRight">
                        <div className="TextFieldForm">
                        <TextField className="TextFieldMultiRow" 
                                variant="outlined" 
                                multiline
                                rows={8}
                                placeholder="Votre message" 
                                inputRef={commentRef}/>
                                </div>
                                </div>
                    </div>
                </div>
                <div style={{height: '50px'}}></div>
                <div className="ButtonContainer">
                    <CustomButton className="ButtonStyle" variant="outlined" color="primary" onClick={(sendButtonClicked)}>ENVOYER</CustomButton>
                </div>
                <div style={{height: '50px'}}></div>
            </div>
            <div className="FooterBorder"></div>
            <img className="FooterImgLogo" src={footerLogo} alt="header" />
        </div>
    );
}

export default Step2Screen;
