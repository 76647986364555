import axios from 'axios'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const sendMail = (data) => {
  console.log(data)
  const params = new URLSearchParams();
  params.append('email', data.email);
  params.append('name', data.name);
  params.append('comment', data.comment);

    axios.post('/mail.php', params)
      .then((response) => {
        console.log(response);
      }, (error) => {
        console.log(error);
      });
}